<template>
    <div class="component-paragraph" v-if="component" aria-label="Seção Banner Paragrafo Amil One">
        <div class="paragraph" ref="wrapperParagraph">
            
            <img v-if="component.image.data" :src="api_url + component.image.data.attributes.url" :alt="component.alternativeText" class="paragraph__image_bg" ref="image_bg">
            <div v-if="component.svg_element" class="banners__element_bg banners__element_svg paragraph__element_bg"  v-html="component.svg_element" alt="Elemento gráfico ilustrando o conteúdo do parágrafo"></div>
            <img v-if="!component.svg_element && component.bg_element.data" :src="api_url + component.bg_element.data.attributes.url" :alt="component.alternativeText" class="paragraph__element_bg">        
            <div class="container">        
                    <div class="paragraph__text">
                        <h2 class="paragraph__title">{{component.title}}</h2>
                        <div class="paragraph___text_paragraph" v-html="component.text">
                        </div>
                        <a v-if="component.button_text" :href="component.button_link" class="paragraph__link" aria-label="Botão para acessar mais informações">{{component.button_text}}</a>
                    </div>
            </div>
            
        </div>    
    </div>    
</template>
<style lang="styl">    
    .paragraph {
        
    
        .banners__element_bg {
            
            @media $large-mobile {
                display: none;
            }
        }
        margin: 0;
        position: relative;   
        height: 100%;     
        overflow: hidden;
        @media (max-width: 1024px) {
            padding: 0 20px;
        }
        .container {
            position: relative;        
            z-index:100;
            height:100%;            
        }
        .paragraph__title {
            font-weight: 700;
            font-size: 40px;
            line-height: 42px;
            padding-top: 50px;       
            @media $large-mobile {
                font-size: 20px;
                margin-bottom: 30px;
                font-weight: 400;
                font-size: 28px;
                line-height: 29px;
                padding-top: 0;
            }
        }
        
        .paragraph__text {            
            display: flex;
            height:100%;
            max-width: 45%;
            justify-content: center;            
            flex-direction: column;
            padding: 70px 80px 40px 0px;
            font-weight: 400;
            font-size: 22px;
            line-height: 38px;
            @media $large-mobile {
                max-width: 100%;
                font-weight: 400;
                font-size: 18px;
                line-height: 32px;
                padding: 0;
            }
        }
        .paragraph___text_paragraph {
            margin-top:40px;
            width: 462px;
            font-weight: 400;
            font-size: 22px;
            line-height: 38px;
            color: #3B3B3A;         
            @media $large-mobile {
                text-align:left;
                line-height: 32px;
                width: 100%;
                font-size:18px;
                margin-top:0;
            }
            @media (max-width:1200px) { 
                line-height:28px;
            }
        }
        .paragraph__link {
            color: #D5086E;
            margin-top: 50px;
            font-weight: 500;
            padding-bottom: 32px;
            display:block;
            align-self: flex-start;
            @media $large-mobile {
                font-size: 16px;
                line-height:16.666px;
                margin-top:15px;
                align-self: center;
            }
            @media (max-width:1120px) {
                
                margin-top:0;
            }
            &:after {
                content:'';
                display:block;
                width: 100%;
                height:2px;
                background: #D5086E;
                @media $large-mobile {
                    margin: 0 auto;
                }
            }
        }
        @media $large-mobile {
            text-align: center;
            height: auto !important;
            padding-bottom: 80px;
        }
        .banners__element_svg svg {
            height: auto;
            width: 100%;            
            path {
                animation: rotatingBanner 6s infinite;
                transform-box: fill-box;
                transform-origin: 50%;
            }    
            image {
                animation: rotatingBanner 5s infinite ease-in-out;
                animation-direction: reverse;
                transform-box: fill-box;
                transform-origin: 50%;
            }
        }
    }        
    .paragraph__image_bg {
        position:absolute;
        top:0;
        right:0;
        z-index:0;        
        width:65%;
        height:auto;
        @media $large-mobile{
            display: none;
        }
    }

    .paragraph__element_bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 80%;
        height: auto;
        @media $large-mobile{
            display: none;
        }
    }
@keyframes rotatingBanner {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
<script>

export default {
   
    name: "ComponentSectionsParagraph",    
    props: {
        component: Object
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
    
    mounted() {
                
        this.calculateImageSize();
        //on resize calculate image size again
        window.addEventListener('resize', this.calculateImageSize);
    }, 

    methods: {
        calculateImageSize() {
            
            //get image bg size
            const image_bg = this.$refs.image_bg;        
            const image = new Image();
            image.src = image_bg.src;            
            image.onload = () => {
                const image_bg_width = image.naturalWidth;
                const image_bg_height = image.naturalHeight;        
                const wrapper = this.$refs.wrapperParagraph;
                const proportion = image_bg_height / image_bg_width;                
                const document_width = document.documentElement.clientWidth;                
                const new_image_width = document_width * 0.65;
                const new_image_height = new_image_width * proportion;                    
                new_image_height > 768 ? wrapper.style.height = '768px' : wrapper.style.height = new_image_height + 'px';       
            }
            
        }
    }
}
</script>


