
<template v-if="components">
  <Header @forceRender="forceRender" />
    <main class="structure main-page"  id="home">                     
      <ComponentList :components="components" ref="ComponentList" :pageId="pages.data.id"></ComponentList>                          
      <Arrow /> 
    </main>
  <Footer />
</template>
<style>
.structure {
  overflow: hidden;
  background: #f6f6f6;
}
</style>
<script> 
import axios from "axios";
import { useStore } from 'vuex'
import Header from "../components/Header.vue";
import Arrow from "../components/Arrow.vue";
import Footer from "../components/Footer.vue";
import titleMixin from "../mixins/mixinTitle";
import metaTagMixin from "../mixins/mixinMetaTags";
import { useRoute } from "vue-router";
import ComponentList from '../components/ComponentList';
export default {
  name: 'PageSite',
  title () {    
    return `${this.pageTitle}`
  },
  metaTags() {    
    return this.SEO;
  },
  tags() {
    return this.TagsGTM;
  },
  cookies() {
    return this.cookie;
  },
  url_canonical() {
    return this.canonical;
  },
  url() {
    return this.final_url;
  },
  description() {
    return this.excerpt;
  },
  image() {
    return this.image;
  },

  components: {
    ComponentList,
    Header,
    Arrow,
    Footer
  },
  mixins: [
    titleMixin,
    metaTagMixin
  ],  
      
  props: {
    slug: String
  }, 

  async setup(props) { 
    const store = useStore();
    const active_level = store.state.level
    const api_url = process.env.VUE_APP_STRAPI_API_URL;
    const route = useRoute();
    let slug = route.params.slug ? route.params.slug : props.slug;

    let url = window.location.href;
      if (url) {
        const link = url.replace('#scroll', '');
        const head = document.head;

        // Check if there is already a link with a canonical URL
        const canonicalLink = Array.from(head.querySelectorAll('link')).find((element) => {
          return element.getAttribute('rel') === 'canonical' && element.getAttribute('href') === link;
        });

        if (canonicalLink) {
          console.log('Já existe um link com URL canonical');
        } else {
          // Insert the link with a canonical URL if it doesn't exist
          head.insertAdjacentHTML('beforeend', `<link rel="canonical" href="${link}" />`);
          console.log('Link com URL canonical inserido');
        }
      }

    
    const res = await axios.get(api_url+`/pages/getBySlug/${slug}?filters[level]=${active_level}&populate=*`).catch(err => {
      if (err.response.status == '404') {
        window.location.href = '/404'
      }
    });

    const pages = res.data;            
    const components = pages.data.attributes.components;

    const pageTitle = pages.data.attributes.SEO && pages.data.attributes.SEO.title ? pages.data.attributes.SEO.title : pages.data.attributes.title;        
     
    

    let TagsGTM = [];
    let cookie = [];
    let SEO = [];

    const renderComponent = true;
    
    await axios.get(api_url+`/configuracao?populate=gtm.tags`).then(function (response) {

      const cookiePage = pages.data.attributes.COOKIE && pages.data.attributes.COOKIE.cookies ? pages.data.attributes.COOKIE.cookies : [];

      const cookieDefault = response.data.data.attributes.cookie_script != null ? [{
        script : response.data.data.attributes.cookie_script
      }] : [];      
      
      let testCookiePage = false;

      if(cookiePage.length > 0){
        cookiePage.forEach(element => {
          if(element.script !== null && element.script !== ''){
            testCookiePage = true;
          }
        });
      }

      cookie = testCookiePage ? cookiePage : cookieDefault;
      // END COOKIES
      
      // GOOGLE TAG MANAGER -- CHANGE TO GET GTM FROM THE SINGLE TYPE "configuracao"
      const TagsGTMDefault = response.data.data.attributes.gtm != null ? response.data.data.attributes.gtm.tags : [];
      const TagsGTMPage = pages.data.attributes.TAGS && pages.data.attributes.TAGS.tags ? pages.data.attributes.TAGS.tags : [];
        
      let testTagGtmPage = false;
      if(TagsGTMPage.length > 0){
        TagsGTMPage.forEach(element => {
          if(element.head_gtm !== null && element.head_gtm !== ''){
            testTagGtmPage = true;
          }

          if(element.body_gtm !== null && element.body_gtm !== ''){
            testTagGtmPage = true;
          }
        });
      }
      TagsGTM = testTagGtmPage ? TagsGTMPage : TagsGTMDefault;
      // END GOOGLE TAG MANAGER
    })
    .catch(function (error) {
      console.warn("Erro ao acessar /configuracao?populate=gtm.tags");
      console.error(error);
    });

    await axios.get(api_url+`/configuracao?populate=SEO.metatags`).then(function (response) {           
      // SEO - METATAGS
      const metaTagsDefault = response.data.data.attributes.SEO && response.data.data.attributes.SEO.metatags ? response.data.data.attributes.SEO.metatags : [];

      const metaTagsPages = pages.data.attributes.SEO && pages.data.attributes.SEO.metatags ? pages.data.attributes.SEO.metatags : [];

      let testMetaTAGS = false;
      let testTagGtmPage = false;
      if(metaTagsPages.length > 0){
        metaTagsPages.forEach(element => {
          if(element.name !== null && element.name !== ''){
            testTagGtmPage = true;
          }
          if(element.content !== null && element.content !== ''){
            testTagGtmPage = true;
          }
        });
      }


      SEO = testMetaTAGS ? metaTagsPages : metaTagsDefault;

      console.log('seo', SEO)

      })
      .catch(function (error) {
        console.warn("Erro ao acessar /configuracao?populate=SEO.metatags");
        console.error(error);
    });
    const excerpt = pages.data.attributes.excerpt ? pages.data.attributes.excerpt.replace(/(<([^>]+)>)/gi, "") : '';            
    const featured_image = pages.data.attributes.featured_image && pages.data.attributes.featured_image.data ? pages.data.attributes.featured_image.data.attributes.url : '';
    const final_url = `${process.env.VUE_APP_SITE_URL}/${pages.data.attributes.slug}`;
    //if featured_image starts with http, it's an external image, so we don't need to add the api_url
    const image = featured_image && featured_image.startsWith('http') ? featured_image : `${process.env.VUE_APP_STRAPI_URL}${featured_image}`;
    
    return {      
      components,
      pages,
      pageTitle,
      SEO,
      TagsGTM,
      cookie,
      active_level,            
      api_url,
      renderComponent,
      excerpt,
      image,
      final_url
    }
   },
   mounted() {
     const hash = window.location.hash;
    if(hash) {
      const el = document.querySelector('.component-page-selector');      
      if(el) {      
        const nextEl = el.nextElementSibling;
        if(nextEl) {          
          const pageScrollY = nextEl.getBoundingClientRect().top + window.scrollY - 150;
          window.scrollTo({ top: pageScrollY, behavior: 'smooth' });
        }        
      }
    }
   }
   
};
</script>
