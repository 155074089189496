<template>
  <div>
    <div v-if="component" class="imagesGrid">
       <div class="center-content">
            <h2 v-if="component.title" v-html="component.title"> </h2>
          <div class="Images_Grid">
              <div v-for="grid in component.imagesGrid" 
              :key="grid"
              class="ImgGrid">
                    <div class="grid__item"> 
                        <img v-if="grid.images.data"
                        :src="api_url + grid.images.data.attributes.url" 
                        :alt="grid.images.data.attributes.alternativeText">
                    </div>
              </div>
          </div>
              <div class="title-section">             
                 <p class="t-lh-25" 
                 v-if="component.text" 
                 v-html="component.text">
                 </p>                
              </div>                
       </div>
    </div>
  </div>
</template>
<style lang="styl">
.imagesGrid {
.center-content {
  h2 {
    text-align: center;
    padding: 80px;
    font-size: 1.125rem;
  }
  .Images_Grid {
      display: grid;
      place-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media $mobile-max {
        grid-template-columns: 1fr 1fr;
      }
      @media $tablet-min {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media $small-desktop-min {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      
  }
  .title-section {
    padding: 50px;
    p {
      font-size: 1.125rem;
      text-align: center;
    } span {
      font-weight: bold;
    }
  }
}
}
</style>
<script>
export default {
  name: "ComponentSectionsImagesGrid",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>
