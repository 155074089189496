<template>
  <div>
    <div v-if="component">
        <section class="imgWithText" 
        :class="{ bg_lightGrey : component.gray_background }"
        :style="{ paddingTop: component.padding_top + 'px'}">
          <div class="center-content ">
            <div class="imgWithText_content" 
            :class="{ row_reverse : component.to_right}">
              <img
                class="desk-version"
                :src="api_url + component.image.data[0].attributes.url"
              />
              <div class="title-section">
                <h2 
                class="t-lh-56 t-darkBlue t-sb t-black" 
                v-html="component.title">
                </h2>
                <img
                  class="mobile-version"
                  :src="api_url + component.image.data[0].attributes.url"
                />                
                <div class="icon_info" 
                v-for="img in component.icon_line" 
                :key="img.id">
                    <img 
                    :src="api_url + img.icon_img.data.attributes.url" 
                    alt="" v-if="img.icon_img">
                    <p v-if="img.icon_text">{{ img.icon_text }}</p>
                </div>
                 <p v-if="component.text" 
                 v-html="component.text">
                 </p>
              </div>
            </div>
          </div>
        </section>
    </div>
  </div>
</template>
<style lang="styl">
.imgWithText{
    padding: 80px 0px 50px;
    .imgWithText_content{
      display: flex;
      flex: 1;
      position: relative;
      gap: 80px;
        img{
            @media $large-mobile{
                margin: 0;
                position: initial;
            }

            @media $mobile-max{
                max-width: 100%;
            }
        }
        @media $large-mobile{
            grid-template-columns: 1fr;         
        }
    }

    .title-section{
        max-width: 450px;
        align-self: center;
        h2 {
          font-size: 45px;
          margin-bottom: 50px;
          span {
            color: #461bff;
          }
        }

        p{
          line-height: 1.5;    
          font-size: 18px;
          span {
            color: #461bff;
            font-weight: bold;
          } 
          a {
            color: #461bff;
            text-decoration: underline;
            font-weight: bold;
            cursor: pointer;
          }           
          @media $large-mobile{
              text-align: center;
          }
          @media $mobile-max {
            margin-bottom: 30px;
          }
          li {
              list-style: none;
          }
          li::before {
              background-color: $blue;
              border-radius: 50%;
              content:"";
              display: inline-flex;
              height: 11px;
              margin-right: 7px;
              width: 11px; 
          }          
        }

        ul{
            font-size: 18px;
            line-height: 27px;
            li:before{
                background-color: $blue;
                border-radius: 50%;
                content:"";
                display: inline-flex;
                height: 11px;
                margin-right: 7px;
                width 11px;
            }

            @media $large-mobile{
                text-align: left;
                margin:auto;
            }
        }
        .icon_info  {
            gap: 20px;
            display: flex;
            padding-bottom: 30px;
            @media $large-mobile {
              flex-direction: column;
              justify-items: center;
              display: block;
              margin-top: 30px;
              img {
                width: 40px;
              }
            }
            p {
                margin-bottom: 0;
                font-size: 18px;
            }                           
            
        }         

        @media $large-mobile{
            margin: 0;
            text-align: center;
            padding:0;
            max-width: 500px;
            display: grid;
            justify-items: center;
            h2{
                margin-bottom: 45px;
                text-align: center;
                margin-top: 30px;
            }
            p {
              text-align: center;
              margin-top: 30px;
            }
        }
        @media $mobile-max {
          margin: 0;
          padding-top: 0;
        }
    }

    .row_reverse{
      .title-section{
        margin-right: auto;
        margin-left: initial;
        
        @media $large-mobile{
          text-align:center;
          margin: auto;

          p{
            text-align:center;
            margin: auto;
          }
        }
      }
    }

    .desk-version{
      max-width: 100%;
        @media $large-mobile{
            display: none;
        }
    }

    .mobile-version{
        display: none;

        @media $large-mobile{
            display: block;
            border-radius: 20px;
        }
    }

    @media $large-mobile{
        margin: 0px 0px 0px;
        padding: 36px 0px;
    }

     @media $mobile-max{
        .t-45{
            font-size: 30px;
        }

        .t-lh-56{
            line-height: 40px;
        }
    }
}

.imgWithText.bg_lightGrey{
    margin: 144px 0px 0;
    padding:30px 0 30px !important;
    min-height: auto;
    @media $large-mobile {
      margin: 0;
      padding: 30px 0 0 !important;
    }
}
</style>
<script>
export default {
  name: "ComponentSectionsImgWithText",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>
