<template>
  <div>
    <div v-if="component" class="cards">
      <div class="container">
        <p class="pre-text">{{ component.title }}</p>
        <div class="cards__list" ref="slider__wrapper">
          <swiper
            class="card-list__slider swiper-cards"
            ref="swiper"
            v-if="cards.length > 0"
            :breakpoints="swiperOptions.breakpoints"
          >
            <swiper-slide
              v-for="component in cards"
              :key="component.id"
              ref="swiperSlide"
            >
              <Card :component="component" />
            </swiper-slide>
          </swiper>
          <div class="navigation-swiper" :class="{show: showArrows}">
            <button @click="prevSwiper('.swiper-cards')" class="prev-swiper-nav">
              Prev
            </button>
            <button @click="nextSwiper('.swiper-cards')" class="next-swiper-nav">
              Next
            </button>
          </div>
        </div>
        <div class="cards__link" v-if="component.url_link">
          <a
            v-if="component.link_text"
            :href="component.url_link"
            class="button button--primary"
          >
            {{ component.link_text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="styl">
.cards {
  padding:100px 0;
  .pre-text {
    text-align:center;
  }
  .container {
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      text-align: center;
      color: #3B3B3A;
      margin-bottom: 30px;
    }
  }
  .cards__link {
      font-family: 'Amil Typeface';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      text-align: center;
      a {
          color:#D5086E;
      }
      &:after {
          content: '';
          display: block;
          width: 150px;
          max-width:100%;
          height: 4px;
          background: #D5086E;
          margin: 10px auto;
      }
  }
  .prev-swiper-nav {
        background: url(../../images/prev.svg);
        background-repeat: no-repeat;
        width:30px;
        height:30px;
        border:0;
        text-indent:-9999px;
        margin:0 10px;
    }

    .next-swiper-nav {
        background: url(../../images/next.svg);
        background-repeat: no-repeat;
        border:0;
        width:30px;
        height:30px;
        text-indent:-9999px;
        margin:0 10px;

    }
    .navigation-swiper {
        width:100%;
        text-align:center;

        display:none;
        margin-top:40px;
        &.show{
            display:block;
        }
    }
  .cards__list {
        overflow: hidden;
        max-width: 80%;
        margin-bottom: 77px;
        margin-top:40px;
        width:720px;
        margin:40px auto 40px auto;

    }
    .card-list__slider {
        display:flex;
        gap: 20px;
        width: 100%;
        justify-content: center;

        .card {
            background: #FFF;
            width: 146px;
            height: 161px;
            padding:20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media $mobile-max {
                width: 140px !important;
                height: 160px !important;
            }
        }
    }
}
</style>
<script>
import Card from "../Card.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
export default {
  name: "ComponentSectionsCards",
  props: {
    component: Object,
  },
  components: {
    Card,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
      cards: [],
      showArrows: false,
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          699: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          990: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1100: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          1194: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
        },
      },
    };
  },
  methods: {
    nextSwiper(swiper) {
      const swiperEl = document.querySelector(swiper);
      swiperEl.swiper.slideNext();
    },
    prevSwiper(swiper) {
      const swiperEl = document.querySelector(swiper);
      swiperEl.swiper.slidePrev();
    },
    checkArrows() {
      const swiperEl = this.$refs.swiper;
      const swiperSlide = this.$refs.swiperSlide;
      // get swiper El width

      if(swiperSlide) {
      
        const swiperElWidth = swiperEl.$el.clientWidth;
        // get swiper slide width
        const swiperSlideWidth = swiperSlide[0].$el.clientWidth;
        const totalWidth = this.cards.length * swiperSlideWidth;
        if (totalWidth > swiperElWidth) {
            this.showArrows = true;
        } else {
            this.showArrows = false;
        }
      }
    },
  },
  mounted() {
    this.cards = this.component.cards;
    this.checkArrows();
    window.addEventListener("resize", this.checkArrows);
  },
};
</script>
