<template>
    <figure v-if="component.image.data">
        <img :src="api_url + component.image.data.attributes.url"
        :alt="component.image.data.attributes.alternativeText">
    </figure>
</template>
<script>
export default {
    name: "ComponentPartsImage",
    props: {
        component: Object,
    }, 
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
}
</script>