<template>
  <div>
    <div v-if="component">
      <section class="imgWithList">
        <div class="center-content">
          <div class="flexed keep-flex aic-center jfc-between">
            <div class="slick-exames">
              <div class="exames-pictures">
                <img
                  src="<?php echo $url . 'images/unitedhealth-group.jpg'?>"
                />
                <img
                  src="<?php echo $url . 'images/unitedhealth2-group.jpg'?>"
                />
                <img
                  src="<?php echo $url . 'images/unitedhealth3-group.jpg'?>"
                />
              </div>
              <div class="arrow-exames">
                <div class="prev">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.503"
                    height="23.619"
                    viewBox="0 0 13.503 23.619"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-back"
                      data-name="Icon ionic-ios-arrow-back"
                      d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
                      transform="translate(-11.251 -6.194)"
                      fill="#989898"
                    />
                  </svg>
                </div>
                <div class="next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.503"
                    height="23.619"
                    viewBox="0 0 13.503 23.619"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-back"
                      data-name="Icon ionic-ios-arrow-back"
                      d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
                      transform="translate(24.754 29.813) rotate(180)"
                      fill="#461bff"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="title-section">
              <h2 class="t-45 t-lh-56 t-darkBlue t-sb mb-2">
                {{ component.title }}
              </h2>
              <div class="slick-exames-mobile">
                <div class="exames-pictures-mobile">
                  <img
                    src="<?php echo $url . 'images/unitedhealth-group.jpg'?>"
                  />
                  <img
                    src="<?php echo $url . 'images/unitedhealth2-group.jpg'?>"
                  />
                  <img
                    src="<?php echo $url . 'images/unitedhealth3-group.jpg'?>"
                  />
                </div>
                <div class="arrow-exames-mobile">
                  <div class="prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.503"
                      height="23.619"
                      viewBox="0 0 13.503 23.619"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
                        transform="translate(-11.251 -6.194)"
                        fill="#989898"
                      />
                    </svg>
                  </div>
                  <div class="next">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13.503"
                      height="23.619"
                      viewBox="0 0 13.503 23.619"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
                        transform="translate(24.754 29.813) rotate(180)"
                        fill="#461bff"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <p class="t-18 t-lh-25">
                {{ component.text }}
              </p>
              <ul class="t-18 t-lh-30">
                <li v-for="topic in component.topics" :key="topic">
                  {{ topic.text }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="styl">
    .exames{
        padding: 170px 0px 105px;

        .flexed{
            margin-bottom: 170px;

            img{
                @media $large-mobile{
                    max-width: 390px;
                    position: initial;
                }

                @media $mobile-max{
                    max-width: 100%;
                }
            }

            @media $large-mobile{
                margin-bottom: 40px;
                flex-direction: column;
            }
        }

        .flexed:last-child{
            margin-bottom: 0px;
            gap: 55px;
        }

        .title-section{
            margin-left: auto;
            max-width: 440px;

            p{
                max-width: 440px;
                margin-bottom:35px;

                @media $large-mobile{
                    text-align: left;
                }
            }

            ul{
                li:before{
                    background-color: $blue;
                    border-radius: 50%;
                    content:"";
                    display: inline-flex;
                    height: 11px;
                    margin-right: 7px;
                    width 11px;
                }

                @media $large-mobile{
                    margin-right: auto;
                    max-width: 200px;
                    text-align: left;
                }

                @media $mobile-max{
                    max-width: 250px;
                }
            }

            @media $large-mobile{
                margin: 0px auto 0px;
                text-align: center;

                h2{
                    margin-bottom: 45px;
                }

                img{
                    margin-bottom: 45px;
                }
            }

            @media $mobile-max{
                .t-lh-30{
                    line-height: 40px;
                }
            }
        }

        .title-section:nth-child(2){
            margin-right: auto;
        }

        .slick-exames{
            @media $large-mobile{
                display: none;
            }
        }

        .slick-exames-mobile{
            display: none;
            @media $large-mobile{
                display: block;
                position: relative;


                .slick-dots{
                    bottom: 0px;
                    position: absolute;
                    margin: auto;
                }
            }
        }

        .slick-exames,
        .slick-exames-mobile{
            position relative;
            .exames-pictures,
            .exames-pictures-mobile{
                max-width: 500px;

                @media $large-mobile{
                    margin: auto;
                    max-width: 385px;
                }

                @media $mobile-max{
                    max-width: 270px;
                }
            }
        }

        .slick-dots {
            bottom: -45px;
            display: flex;
            justify-content: center;
            left: 0;
            margin: 0;
            padding: 1rem 0;
            position: absolute;
            right: 0;

            list-style-type: none;

            z-index: 9900;

            li {
                margin: 0 0.25rem;
            }

            li:before{
                display: none !important;
            }

            button {
                display: block;
                width: 12px;
                height: 12px;
                padding: 0;

                border: none;
                border-radius: 100%;
                background-color: $lightGrey;

                text-indent: -9999px;
            }

            li.slick-active button {
                background-color: $blue;
            }
        }

        .arrow-exames,
        .arrow-exames-mobile{
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            width: 100%;
            max-width: 580px;

            .slick-arrow{
                cursor: pointer;
                path{
                    fill: $blue;
                }
            }

            .slick-arrow.slick-disabled{
                path{
                    fill: #989898;
                }
            }

            .prev{
                margin-left: -25px;
            }

            .next{
                margin-right: -25px;
            }

            @media $large-mobile{
                display: none;
            }
        }

        @media $large-mobile{
            padding: 55px 0px 65px;

        }

        @media $mobile-max{
            .t-45{
                font-size: 30px;
            }

            .t-lh-56{
                line-height: 40px;
            }
        }
    }
</style>
<script>
export default {
  name: "ComponentSectionsImgWithList",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>
