<template v-if="components">
  <Header />
  <div class="blog-page" v-if="pages.data.attributes.publishedAt !== null">
    <div class="container">
      <div class="col-left">
        <div class="title-noticia">
          <h2 class="pre-title">
            <div v-if="pages.data.attributes.categories.data.length > 0">
              <img v-if="pages.data.attributes.categories.data[0].attributes" :src="strapi_url + pages.data.attributes.categories.data[0].attributes.image.data.attributes.url" class="icon-category">
              <a :href="`/noticias/categoria/${pages.data.attributes.categories.data[0].attributes.slug}`" v-html="pages.data.attributes.categories.data[0].attributes.name"></a> - <span class="date" v-if="pages.data.attributes.publication_date">{{ formatDate(pages.data.attributes.publication_date) }}</span>
              <span class="date" v-else>{{ formatDate(pages.data.attributes.publishedAt) }}</span>
            </div>
          </h2>

          <h1 class="noticias-title mh-85">{{ pages.data.attributes.title ? pages.data.attributes.title : pages.data.attributes.title_materia }}</h1>
        </div>
        <div class="featured_image_post" v-if="pages.data.attributes.featured_image.data">
          <picture>
            <source v-if="pages.data.attributes.featured_image_mobile.data" :srcset="strapi_url + pages.data.attributes.featured_image_mobile.data.attributes.url" media="(max-width:599px)">
            <img :src="strapi_url + pages.data.attributes.featured_image.data.attributes.url" :alt="pages.data.attributes.featured_image.data.attributes.alternativeText" />
          </picture>
        </div>
        <main class="structure main-page">
          <ComponentList :components="components"></ComponentList>
        </main>
      </div>
      <div class="col-right">
        <div class="compartilhamento">
          <ComponentSectionsShareSocialMedia></ComponentSectionsShareSocialMedia>
        </div>
        <h2 class="title-sidebar">Últimas Publicações</h2>
        <div v-for="noticia in noticias">
          <a :href="'/noticias/' + noticia.attributes.slug" class="item-noticias-sidebar">
            <figure class="img-noticia-sidebar" v-if="noticia.attributes.featured_image && noticia.attributes.featured_image.data">
              <img :src="strapi_url + noticia.attributes.featured_image.data.attributes.url">
            </figure>
            <div class="text-noticia-sidebar">
              <p v-if="noticia.attributes.categories.data.length > 0"><strong>{{ noticia.attributes.categories.data[0].attributes.name }}</strong></p>
              <p>{{ noticia.attributes.title }}</p>
            </div>
          </a>
        </div>

      </div>
    </div>
  </div>

  <router-link v-else to="/404"></router-link>

  <Footer />
</template>
<style lang="styl">
.featured_image_post {
  padding:20px 0;
  img {
    width: 100%;
  }
}
.pre-title {
  font-weight:300;
  font-size:28px;
  /*margin:40px 0 0 0;*/
  vertical-align: middle;
  .date {
    font-size:16px;
    vertical-align: middle;
  }
  a {
    &:hover {
      color:#D5086E;
    }
  }
  @media $mobile-max {
    font-size: 20px;
  }
}
a.item-noticias-sidebar {
  display:flex;
  align-items:flex-start;
  gap:10px;
  margin-bottom:20px;
  &:hover {
    color:#d5086e !important;
  }
  .text-noticia-sidebar {
    p {
      margin-bottom:10px;
    }
  }
}
.img-noticia-sidebar {
  width: 100px;
  height: 100px;
  overflow: hidden;
  flex-shrink:0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.col-left {
  width:65%;
  
  .structure {
    background:transparent;
    h2, h3, h4, h5 {
      color:#d5086e;
      font-weight:300;
      padding:20px 0;
    }
    .center-content {
      margin:0;
      padding:0;
    }
  }
  strong {
    color:#d5086e;

  }
  @media $mobile-max {
    width:100%;
  }

}
.title-noticia {
  @media $mobile-max {
    padding:0 16px;
  }
}
.blog-page {
  .noticias-title {
    padding:0;    
    font-size: 35px;
    @media $mobile-max {
      font-size: 28px;
    }

  }

  .mh-85{
    min-height: 85px;
  }

  .container {
    display:flex;
    flex-wrap:wrap;
    align-content: flex-start;
    padding-top: 85px;
  }
  .col-right {
    width:35%;
    background:transparent;
    padding-left:40px;
    /*margin-top:85px;*/
     @media $mobile-max {
      width:100%;
      padding:0 16px;
      margin-top:20px;
    }

    @media $large-mobile {
      .compartilhamento {
        display: none;
      }
    }
  }
  .title-sidebar {
    border-bottom:1px solid #D5086E;
    color: #D5086E;
    font-size: 35px;
    margin-bottom:40px;
    font-weight:300;
    @media $mobile-max {
      font-size: 28px;
    }
  }
  .icon-category {
    width: 40px;
    height: auto;
    margin-right: 10px;
    vertical-align: middle;
    
  }
}
</style>
<script>
import axios from "axios";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import titleMixin from "../mixins/mixinTitle";
import metaTagMixin from "../mixins/mixinMetaTags";
import { useRoute } from "vue-router";
import ComponentList from '../components/ComponentList';
import ComponentSectionsShareSocialMedia from "@/components/dynamics/ComponentSectionsShareSocialMedia";
export default {
  name: 'PageNoticia',
  title() {
    return `${this.pageTitle}`
  },
  metaTags() {
    return this.metaTagsTags;
  },
  tags() {
    return this.TagsGTM;
  },
  cookies() {
    return this.cookie;
  },
  description() {
    return this.excerpt;
  },
  image() {
    return this.image;
  },
  url() {
    return this.url;
  },
  mixins: [
    titleMixin,
    metaTagMixin
  ],

  components: {
    ComponentList,
    Header,
    Footer,
    ComponentSectionsShareSocialMedia
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('pt-br', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
      return `${da}/${mo}/${ye}`;

    },
    formatDate2(date) {
      const d = new Date(date);
      const ye = new Intl.DateTimeFormat('pt-br', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('pt-br', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('pt-br', { day: '2-digit' }).format(d);
      return `${da}/${mo}/${ye}`;

    },
    setUrlCanonical() {
      const url = window.location.href;
      if (url) {
        const canonicalTag = `<link rel="canonical" href="${url}" />`
        const head = document.head;
        const metaCanonical = document.querySelector('link[rel="canonical"]');

        if (!metaCanonical) {
          head.insertAdjacentHTML('beforeend', canonicalTag);
        }
        else {
          metaCanonical.setAttribute('href', url);
        }
      }
    },
    async setMetaTags() {
      const params = this.$route.params;
      const api_url = process.env.VUE_APP_STRAPI_API_URL;
      let url = api_url + `/noticias/`;

      const res = await axios.get(url);

      res.data.data.forEach((item) => {

      });
    },
    setTargetBlank() {
      const links = document.querySelectorAll('.structure .text-without-imagem a');
      if (links) {
        links.forEach(link => {
          if (link.getAttribute('target') === '_blank') {
            console.log('já existe')
          } else {
            link.setAttribute('target', '_blank');
          }
        })
      }
    },
  },
  mounted() {
    this.setUrlCanonical();
    this.setTargetBlank();

  },
  async setup() {
    const api_url = process.env.VUE_APP_STRAPI_API_URL;
    const strapi_url = process.env.VUE_APP_STRAPI_URL;
    const route = useRoute();
    const slug = route.params.slug;
    const res = await axios.get(api_url + `/noticias/getBySlug/${slug}?populate=*`);
    const pages = res.data;

    if (pages.data.attributes.publishedAt == null) window.location.href = '/404';

    const components = res.data.data.attributes.components;
    const pageTitle = pages.data.attributes.SEO && pages.data.attributes.SEO.title ? pages.data.attributes.SEO.title : pages.data.attributes.title;
    const metaTagsTags = pages.data.attributes.SEO && pages.data.attributes.SEO.metatags ? pages.data.attributes.SEO.metatags : [];

    const resnot = await axios.get(api_url + `/noticias/?&sort[0]=rank&pagination[limit]=4`);
    const noticias = resnot.data.data;
    const url = `${process.env.VUE_APP_SITE_URL}/noticias/${pages.data.attributes.slug}`;

    //remove html tags
    const excerpt = pages.data.attributes.excerpt.replace(/(<([^>]+)>)/gi, "");
    const image = `${process.env.VUE_APP_STRAPI_URL}${pages.data.attributes.featured_image.data.attributes.url}`;


    let TagsGTM = [];
    let cookie = [];


    const renderComponent = true;

    await axios.get(api_url + `/configuracao?populate=gtm.tags`).then(function (response) {



      const cookiePage = pages.data.attributes.COOKIE && pages.data.attributes.COOKIE.cookies ? pages.data.attributes.COOKIE.cookies : [];

      const cookieDefault = response.data.data.attributes.cookie_script != null ? [{
        script: response.data.data.attributes.cookie_script
      }] : [];



      let testCookiePage = false;

      if (cookiePage.length > 0) {
        cookiePage.forEach(element => {
          if (element.script !== null && element.script !== '') {
            testCookiePage = true;
          }
        });
      }

      cookie = testCookiePage ? cookiePage : cookieDefault;
      // END COOKIES

      // GOOGLE TAG MANAGER -- CHANGE TO GET GTM FROM THE SINGLE TYPE "configuracao"
      const TagsGTMDefault = response.data.data.attributes.gtm != null ? response.data.data.attributes.gtm.tags : [];
      const TagsGTMPage = pages.data.attributes.TAGS && pages.data.attributes.TAGS.tags ? pages.data.attributes.TAGS.tags : [];

      let testTagGtmPage = false;
      if (TagsGTMPage.length > 0) {
        TagsGTMPage.forEach(element => {
          if (element.head_gtm !== null && element.head_gtm !== '') {
            testTagGtmPage = true;
          }

          if (element.body_gtm !== null && element.body_gtm !== '') {
            testTagGtmPage = true;
          }
        });
      }
      TagsGTM = testTagGtmPage ? TagsGTMPage : TagsGTMDefault;
      // END GOOGLE TAG MANAGER
    })
      .catch(function (error) {
        console.warn("Erro ao acessar /configuracao?populate=gtm.tags");
        console.error(error);
      });


    return {
      noticias,
      strapi_url,
      components,
      pages,
      TagsGTM,
      cookie,
      pageTitle,
      metaTagsTags,
      excerpt,
      image,
      url
    }
  }

};
</script>
