<template>    
    <section class="container cards-endereco" v-if="component">
        <h2 class=" cards-endereco__content-title">{{ component.title }}</h2>
        <div class="cards-content">
            <article v-for="card in component.CardsEndereco" :key="card.id" class="cards-endereco__content-card">                         
                <div class="card-endereco">
                    <figure>
                        <img v-if="card.icon.data.attributes" :src="api_url + card.icon.data.attributes.url" :alt="card.alternativeText">
                    </figure>
                    <div class="cards-info">
                        <h3 v-if="card.title">{{ card.title }}</h3>     
                        <p v-if="card.address" v-html="card.address" class="address"></p>
                        <div v-if="card.text" class="text" v-html="card.text"></div>
                    </div>
                </div>
            </article>  
        </div>                      
    </section> 
</template>
<style lang="styl">
.cards-endereco {
    background: #F6F6F6;
    .cards-info {
        padding:26px 16.64px;
    }

    .cards-content {
        borde: 1px solid red;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        padding: 20px 0;
        @media $large-mobile {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            justify-items: center;
            padding: 15px;
        }
        @media $mobile-max {
            grid-template-columns: 1fr;
        }
    }
    .cards-endereco__content-title{  
        text-align: left;
        margin-top: 50px;
        @media $large-mobile {
            padding: 15px 20px;
            text-align: left;
        }
        h2 {
            color: #3B3B3A; 
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 30px;
        }
        .cards-endereco__content-text {
            font-size: 22px;
            font-weight: 400;
            line-height: 38px;
            color: #3B3B3A;
        }
        .cards-endereco__text_featured{
            background: #F6F6F6;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            color: #3B3B3B;
            margin-top: 30px;
        }        
    }
    .cards-endereco__content-card {
        margin-top: 50px;
        text-align: left;
        justify-items: center;
        h2 {
            margin-bottom: 50px;
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            color: #3B3B3A;
        }
        .card-endereco {
            background: #FFFFFF;
            border-radius: 16px;
            text-align: left;
            max-width: 342px;
            height: 377px;
            .text {
                p {
                    margin:0;
                }
            }
            h3 {
                font-weight: 700;
                font-size: 16px;
                line-height: 150%;
                color: #3B3B3A;
                margin-bottom: 8px;
            }
            .address {
                border-bottom: 1px solid #ECECEC;    
                padding-bottom:8px;  
                margin-bottom:8px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #3B3B3A;
                margin-bottom: 15px;
            }
            
            figure {
                img {
                    width: 100%;
                    border-radius: 16px 16px 0 0;
                }
            }
        }
    }  
    &:last-child {
        margin-bottom: 100px;
    }   
}   
</style>
<script>
export default {
    name: "ComponentSectionsCardsEndereco",
    props: {
        component: Object,
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
}
    
</script>


