<template>
    <header class="site-header">
        <div class="container">
            <Nav :idMenu="headerMenu" :logo="menuLogo"></Nav>
        </div>
        <div class="selector-user-wrapper" :class="active_level_short">
            <div class="container flex  navigation-user">
                <SelectUser @forceRender="forceRender"></SelectUser>
                
            </div>
        </div>
    </header>
</template>
<script>
import axios from "axios";
import Nav from "./Nav.vue";
import SelectUser from "./SelectUser.vue";
export default {
    name: 'PageHeader',
    emits: ['forceRender'],
    data() {
        return {
            pages: {},
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            header: {},
            headerMenu: '',
            menuLogo: {},
            linkphone: '',
            active_level: 'Visitante',
            active_level_short: 'visitante',
        };
    },
    components: {
        Nav,
        SelectUser
    },
    methods: {
        getActiveUser() {                                    
            this.active_level = localStorage.getItem('active_level') ?  localStorage.getItem('active_level') : this.active_level = 'Visitante';
            this.active_level_short = localStorage.getItem('active_level_short') ?  localStorage.getItem('active_level_short') : this.active_level_short = 'visitante';            
        },
        forceRender() {
            this.$emit('forceRender');
            this.getActiveUser();
        },
    },
    async mounted() {
        this.getActiveUser();
        const res = await axios.get(this.api_url + "/header?populate=*");
        this.pages = res.data;
        this.header = res.data.data.attributes;
        this.headerMenu = this.header.menu;
        this.menuLogo = this.header.logo;
        if (this.header.phone)
            this.linkphone = this.header.phone.replace(/[^0-9]/g, '');
    },
};
</script>

<style lang="styl">
body {
    padding-top:130px !important;
}
.selector-user-wrapper {
    background:#3B3B3A;
    color:#fff;
    &.prestador {

    }   
    &.corretor {
        background:#D5086E;
    } 
    &.empresa-rh {
        background:#9A1055;
    }
    
    .container {
        @media $mobile-max {  
            display: flex;      
            justify-content:space-between;
        }
    }
}
.navigation-user {
    justify-content: flex-end;
    @media $large-mobile {
        display: flex;
        justify-content: space-between;
    }
}
.site-header {
    border:0;
    box-shadow:0;
    outline:none;
    padding:17px 0 0 0;    
    position:fixed;
    top:0;        
    z-index:500;
    width:100%;
    background:#fff;
}
</style>

