<template>
  <div class="">
    <div v-if="component">
      <section class="section-communication">
        <div class="center-content">
          <div class="communication">
            <div class="communication_text">
              <h2 v-if="component.title">
                {{ component.title }}
              </h2>
              <p class="t-16 t-lh-18" v-html="component.text">
              </p>
            </div>
            <picture>
              <source v-if="component.image_mobile.data.attributes" :srcset="api_url + component.image_mobile.data.attributes.url" media="(max-width:599px)">
              <img v-if="component.image_desktop" :src="api_url + component.image_desktop.data.attributes.url" alt="" class="communication-img" />
            </picture>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style lang="styl">
  .section-communication{
    padding: 100px 0px 0 0;
    .center-content {
        display: flex;
        justify-content: center;
    .communication {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-bottom: 35px;
      @media $mobile-max {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 310px;
      }
      .communication-img {
        @media $mobile-max {
          margin-top: -66px;
          max-width: 308px;
        }
      }
      .communication_text {
        background-color: $purple;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 18px 0 0 18px;
        padding: 0 58px;
        @media $large-mobile {
          padding: 0 15px;
        }
        @media $mobile-max {
          justify-content: flex-start;
          border-radius: 18px 18px 0 0;
          max-width: 305px;
          height: 283px;
          padding: 0;
        }
        h2 {
          font-size: 2.815rem;
          font-weight: bold;
          color: #fff;
          margin-bottom: 30px;
          @media $mobile-max {
            margin-top: 30px;
          }
        }
        p {
          color: #fff;
          font-size: 18px;
          @media $large-mobile {
            font-size: 18px;
            line-height: 1.2;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }    
  
    }
}
</style>
<script>
export default {
  name: "ComponentSectionsCommunication",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>
