<template>
    <div class="active-user" @click="openUserSelector()" :class="active_level_short">
        <picture class="user-selector-icon">   
            <img  v-if="active_level_image" :src="api_front_url + active_level_image" :alt="active_level_image_alt" class="user-selector-image" >
        </picture>
        <span class="user-selector-name">{{active_level}}</span>   
    </div>
    <div class="background-user-selector" :class=" openSelector ? 'show':''">
        <div class="user-selector-wrap">
            <div class="user-selector-container">
                <h2 class="user-selector-title">Escolha seu perfil</h2>
                <button class="close-user-selector" aria-label="Selecionar Usuario" @click="closeUserSelector()">
                    <span class="x-line-1"></span>
                    <span class="x-line-2"></span>
                </button>
                <ul class="user-selector">                    
                    <li v-for="level in levels" :key="level.id" class="user-selector-item">
                        <a href="#" @click="setActiveUser(level.attributes.name, level.attributes.icon.data.attributes.url, level.attributes.icon.data.attributes.alternativeText)" class="user-selector-link">
                            <picture class="user-selector-icon">   
                                <img  v-if="level.attributes.icon.data" :src="api_front_url + level.attributes.icon.data.attributes.url" alt="" class="user-selector-image" >
                            </picture>
                            <span class="user-selector-name">{{level.attributes.name}}</span>                            
                        </a>
                    </li>
                </ul>                
            </div>
        </div>
    </div>
    
    <div class="btn-entrar-wrap" v-if="active_level_short == 'beneficiario' ">
        <a href="https://www.amil.com.br/beneficiario/#/" target="_blank" class="btn-entrar">Entrar</a>
    </div>
</template>
<style lang="styl">
.active-user {    
    display: flex;
    padding: 14px;
    align-items:center;
    position:relative;
    padding-right:20px;
    cursor:pointer;
    &:after {
        font-family: 'FontAwesome';
        content: "\f107";
        font-weight: 900;
        font-size:22px;
        color:#D5086E;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);                        
        display:block;
        color:#fff;
        
    }
}

.btn-entrar {
    border-bottom:2px solid #D5086E;        
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color:#fff;
    &:hover {
        color:#fff;
        
    }
}

.btn-entrar-wrap {
    display: flex;
    justify-content: center;    
    align-items: center;
    padding:0 40px;
    @media $small-mobile-max {
        padding:0 14px;
    }

}

.user-selector {
    padding:15px 0;
    display: flex;
    flex-direction: column;
}

a.user-selector-link {
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 10px 0;
    cursor: pointer;
    position:relative;
    color:#3B3B3A;

    /*red caret to right*/
    &:after {
        font-family: 'FontAwesome';
        content: "\f105";
        font-weight: 900;
        font-size:22px;
        color:#D5086E;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);                        
        display:block;

    }
}
.user-selector-icon {
    margin-right:10px;
    width:33px;
    height:33px;
    border-radius:50%;
    background:#F3F3F3;
    padding:8px;
    display:flex;
    align-items:center;
    justify-items:center;
    img {
        max-width:100%;
    }
}
.background-user-selector {
    
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background: rgba(0,0,0,0.34);
    z-index: 9999;    
    &.show {
        display: block;
        width: 100%;
        height: 100%;
        .user-selector-wrap {
            top:150px;
        }
    }
}
.user-selector-title {
    font-size:1.4rem;
    padding-bottom:20px;
    border-bottom:1px solid #E7E7E7;
} 
.user-selector-wrap {
    position:fixed;
    width:80%;
    max-width:442px;
    background:#fff;
    border-radius:24px;
    padding:24px;
    top:120%;
    left:50%;
    transform:translate(-50%,0);
    transition: top 0.3s ease-in-out;
    .close-user-selector {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background:transparent;            
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        right:24px;
        top:24px;            
        border:0;
        .x-line-1 {
            width:19.5px;
            height:1px;
            background: #595758;
            transform: rotate(-45deg);
            position: absolute;
            
        }
        .x-line-2 {
            position:absolute;
            width:19.5px;
            height:1px;
            background: #595758;
            transform: rotate(45deg);
        }
    }
   
}
</style>
<script>
import axios from "axios";
export default {
    name: 'SelectUserLevel',
    emits: ['forceRender'],
    data() {
        return {
            active_level: 'Visitante',
            active_level_short: 'visitante',
            api_front_url: process.env.VUE_APP_STRAPI_URL,
            levels: {},                  
            api_url: process.env.VUE_APP_STRAPI_API_URL,  
            openSelector: false,
            levels_avalaible: [],   
            active_level_image: null,
            active_level_image_alt: null,
        };
    },
    methods: {              
        closeUserSelector() {            
            this.openSelector = false;
        },
        openUserSelector() {
            this.openSelector = true;
        },
        setActiveUser(user, image, imagealt) {
            this.active_level = user;
            //normalize the string to lowercase, remove all accents and transform \ / and spaces to -
            this.active_level_short = user.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\\/\s]/g, '-');
            this.active_level_image = image;
            this.active_level_image_alt = imagealt;
            //save on localstorage
            localStorage.setItem('active_level', this.active_level);               
            localStorage.setItem('active_level_short', this.active_level_short);
            localStorage.setItem('active_level_image', image);
            localStorage.setItem('active_level_image_alt', imagealt);            
            this.closeUserSelector();                        
            this.$store.commit('setActiveLevel', this.active_level);
            this.$emit('forceRender', true); 
            //reload the page
            window.location.href="/";
        },  
        getActiveUser() {                        
            this.active_level = localStorage.getItem('active_level') ?  localStorage.getItem('active_level') : this.active_level = 'Visitante';
            this.active_level_short = localStorage.getItem('active_level_short') ?  localStorage.getItem('active_level_short') : this.active_level_short = 'visitante';
            this.active_level_image = localStorage.getItem('active_level_image') ?  localStorage.getItem('active_level_image') : this.active_level_image = '/uploads/size_40px_name_Visitante_826daa0f4a.png';   
            this.active_level_image_alt = localStorage.getItem('active_level_image_alt') ?  localStorage.getItem('active_level_image_alt') : this.active_level_image_alt = 'visitante';
            if(! localStorage.getItem('active_level') )   { localStorage.setItem('active_level', this.active_level); }
            if(! localStorage.getItem('active_level_short') )   { localStorage.setItem('active_level_short', this.active_level_short); }
            if(! localStorage.getItem('active_level_image') )   { localStorage.setItem('active_level_image', this.active_level_image); }
            
            
        },

    },      
    async mounted() {
        this.getActiveUser();
        const res = await axios.get(this.api_url+"/levels/?populate=*");
        this.levels = res.data.data;        
        this.levels.forEach(level => {
            const level_avalaible = {
                name: level.attributes.name,
                icon: level.attributes.icon,
            }
            this.levels_avalaible.push(level_avalaible);
        });      
    },
};
</script>
