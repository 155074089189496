<template>
    <div class="component-balloons-text">
        <div v-if="component" class="center-content">       
            <div class="ballons-items" v-for="rede_credenciada in rede" :key="rede_credenciada.id"
            ref="ballons">
                <div class="ballons-items__title" v-if="rede_credenciada.attributes.name">
                    <h2 v-if="rede_credenciada.attributes.name">
                     {{ rede_credenciada.attributes.name }}
                    </h2>
                </div>
                <div class="balloons-item__content">
                    <ul class="rede_credenciada_featured__cidade__list" v-if="rede_credenciada.rede_credenciada">
                        <li v-for="rede_item in rede_credenciada.rede_credenciada" :key="rede_item.id" class="rede_credenciada__cidade__item">
                            <div class="rede_credenciada__cidade__item__title">                            
                                {{rede_item.attributes.title}} <span class="asterisk" v-if="rede_item.attributes.exclusivo_s2500_s2600">*</span>
                            </div>
                        </li>                 
                    </ul>
                </div>
            </div>          
        </div>
    </div>    
</template>
<style scoped lang="styl">
    .component-balloons-text {
        padding-bottom: 50px;
        .center-content {
            .ballons-items {
                margin-bottom:40px;
                @media $large-mobile {
                    padding-left: 20px;
                }
                @media $mobile-max {
                    padding-left: 20px;
                }
            }
           .ballons-items__title {
                position: relative;
                display: flex;
                align-items: center;
                text-align: center;
            h2 {
                padding: 8px 40px;                
                height: 40px;
                border-radius: 8px;
                color: #FFF;
                background: #D5086E;
                margin: 20px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid #FFF;
                font-weight: 700;
                font-size: 22px;
                line-height: 150%;                               
            }
            &::after {
                content: '';
                display: inline-block;
                border: 1px solid #C4C4C4;
                width: 100%;
                position: absolute;
                right: 0;
                z-index: 0;
            }
           } 
        }
        .balloons-item__content{
            ul {
                display: grid;
                grid-template-columns: repeat(3, 1fr); 
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #3B3B3A;
                gap: 20px;
                @media $large-mobile {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                }
                @media $mobile-max {
                    grid-template-columns: 1fr;
                    gap: 0;
                }
                li {
                    display: flex;
                    align-items: center;
                &::before {
                        background-color: #3B3B3B;
                        border-radius: 50%;
                        content:"";
                        display: inline-flex;
                        height: 5px;
                        margin-right: 10px;
                        width: 5px; 
                    }
                }
            }
            .text-featured {
                grid-column: 1 / -1;
                padding: 12px 16px;
                background: #F6F6F6;
                border-radius: 8px;
                margin-top: 10px;
                color: #000000;
            }
            
        }
        
       
    }
    .component-balloons-text .center-content .ballons-items:nth-child(even) {
            h2 {
                background:#3B3B3A
            }   
        }

        .component-balloons-text .center-content h2 {
            z-index: 10; 
            border-right:4px solid #f4f4f4 !important;
        }
</style>
<script>
import axios from 'axios';
export default {
    name: "ComponentSectionsBalloonsWithText",
    props: {
        component: Object,
    },
    methods: {
        strapi_api_url: function () {
            return process.env.VUE_APP_STRAPI_API_URL;
        },
        getCidades: async function () {
            const api_url = this.strapi_api_url();
            let filters = "/cidades?pagination[limit]=5&sort[0]=order";
            let cidades = await axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            const res = cidades;
            return res;
        },
        getRedeCredenciada: async function (cidade) {
            const api_url = this.strapi_api_url();
            let filters = `/rede-credenciadas?sort[0]=rank&filters[cidade][id]=${cidade.id}`;
            let redeCredenciada = axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            return redeCredenciada;
        },
        async setup() {
            const cidades = await this.getCidades();
            const rede = [];
            var promises = [];
            cidades.forEach(element => {
                promises.push( this.getRedeCredenciada(element).then((response) => {
                    element.rede_credenciada = response;
                    rede.push(element);
                }));
            });
            return Promise.all(promises).then(() => {
                return {
                    cidades,
                    rede
                };
            });                    
        },
    },
    async mounted() {
        const data = await this.setup();
        this.rede = data.rede;
        const balon = document.querySelectorAll(".ballons-items__title h2");        
    },
    data() {
        return {
            rede: {},
            styles: {},
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
        };
    },
}
</script>
