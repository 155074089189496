<template>
    <div class="component-cards-bullet">
        <section v-if="component">
        <div class="center-content">
          <div class="title-section">
            <h2 class="t-45 t-lh-56 t-darkBlue t-sb mb-4">
              {{component.title}}
            </h2>
            <div
            class="t-20 t-lh-25 box_text" 
            v-html="component.text"></div>
          </div>             
            <div 
             v-if="component.image_desktop.data"
            class="card-bullet-images flexed keep-flex aic-center" >
                <img                                 
                    class="desk-version"
                    :class="{ opacity: component.remove_image}"
                    :src="api_url + component.image_desktop.data.attributes.url"
                /> 
            </div>
            <div class="cards-bullet" v-for="card in component.CardsWithBullet" :key="card">
                <div class="flexed keep-flex column cards" 
                :class="{ bg_lightGrey: card.gray_background }">
                  <h3 class="t-19 t-lh-18 t-darkBlue t-sb" v-html="card.title"></h3>
                  <ul class="t-16 t-lh-18" v-html="card.text"></ul>
                </div>
            </div>
        </div>     
        </section>
    </div> 
</template>
<style lang="styl">
.component-cards-bullet {
    padding-bottom: 180px;
    margin-top: 55px;
    .center-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        
        .title-section {
            padding: 97px 66px 0 0;
            @media $large-mobile {
                text-align: center;
                padding: 30px 0 0 0;
            }
            .box_text {
                strong {
                    color:  $blue;
                }
            }
            
        }
        .card-bullet-images {        
            grid-row: 1 / 3;
            grid-column: 2;
            display: flex;
            align-self: start;            
            img {
                margin-top: 44px;
            }
            
            @media $large-mobile {
                display: none;
            }
        }
        .cards-bullet {
            width: 100%;
            display: flex;
            justify-content: center;
            max-width: 447px;
            .cards {
                border-radius: 10px;
                padding: 0 15px;
                height: 100%;
                height: 220px;
                width: 100%;
                justify-content: center;
                h3 {
                    font-size: 43px;
                    margin: 40px 0;
                    @media $small-mobile-max {
                        font-size: 37px;
                    }
                }
                ul {
                    color: #505050;
                    font-size: 20px;
                    line-height: 1.2;
                    li {
                        margin-bottom: 20px;
                        @media $large-mobile {
                            padding-bottom: 10px;
                        }
                            &::before {
                            background-color: $blue;
                            border-radius: 50%;
                            content:"";
                            display: inline-flex;
                            height: 11px;
                            margin-right: 10px;
                            width: 11px;         
                        } 
                    }

                }
                @media $large-mobile {
                    padding: 80px 10px;
                    padding: 20px;
                }
            }
            @media $large-mobile {
                margin: 0 auto;
            }

        }
        @media $large-mobile {
            grid-template-columns: 1fr;
        }
    }    
}

.opacity {
    opacity: 0
}
</style>
<script>
export default {   
    name: "ComponentSectionsCardWithBullet",    
    props: {
        component: Object,
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
        };
    },
}
</script>


