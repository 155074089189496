<template>  
  <Suspense>
    <router-view :key="$route.fullPath"></router-view>    
  </Suspense>   
</template>
<style>
  [v-cloak] > * { display:none }
  [v-cloak]::before { content: "loading…" }
</style>

<script>


export default {
  name: "App"
};
</script> 