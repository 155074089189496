<template>
    <section  v-if="component" aria-label="componente profile" id="ProfileCard">                   
        <div class="container" v-for="profile in component.Profile_Cards" :index="0">
            <figure class="profile">
                <img v-if="profile.icon && profile.icon.data" 
                :src="api_url + profile.icon.data.attributes.url" 
                class="profile__icon" width="174" height="174"/>
                <figcaption>
                    <h2 v-if="profile.title" class="profile__title">{{  profile.title  }}</h2>
                    <p v-if="profile.especialidades" v-html="profile.especialidades" class="profile__especialidades"></p>
                    <p v-if="profile.CRM" class="profile__CRM">{{ profile.CRM  }}</p>
                </figcaption>
            </figure>
        </div>
    </section>    
    
</template>

<style lang="styl" scoped>
#ProfileCard {
    .container {
        padding: 40px 0;
        display: flex;
        @media $mobile-max {
            padding: 40px 18px;
        }
        figure {
            display: flex;
            gap: 20px;
            align-items: center;
            .profile__icon {
                max-width: 100%;
                height: auto;
                @media $mobile-max {
                    max-width: 144px;
                    height: 143px;
                }
            }

            figcaption {
                display: flex;
                gap: 8px;
                flex-direction: column;
                h2, p {
                    font-size: 22px;
                    @media $large-mobile {
                        font-size: 12px;
                    }
                }
                .profile__title {
                    font-weight: 700;
                    color: #d5086e;
                }
                .profile__especialidades {
                    color: #3b3b3a;
                    line-height: 1.5;
                }
                .profile__CRM {
                    color: #595758;
                }
            }

        }
    }
}

</style>
<script>
export default {
    name: "ComponentProfileCard",
    props: {
        component: Object,
    }, 
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
             
    },
}
</script>
