<template>
    <section v-if="component">
        <div class="container central-libras">
            <nav class="central-libras__breadcrumb">
                <ul class="t-20 t-lh-28">
                    <li aria-label="Página Inicial"><a href="/">Home</a></li>
                </ul>
            </nav>
            <div class="central-libras_content">
                <h2 v-if="component.title" class="central-libras_content__title">{{ component.title }}</h2>
                <p v-if="component.text" v-html="component.text" class="central-libras_content__text"></p> 
            </div>
            <a v-if="component.link_url" :href="component.link_url" target="_blank"><img :src="api_url + component.image.data.attributes.url" :alt="component.alternativeText"></a>
        </div>
    </section>
</template>
<style lang="styl">
.central-libras {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    @media $large-mobile {
        grid-template-columns: 1fr;
    }
    @media $mobile-max {
        padding: 20px;
    }
    .central-libras__breadcrumb {
        grid-column: 1 / -1;
        margin-top: 30px;
        color: #D5086E;
    }
    .central-libras_content__title {
        font-weight: 500;
        font-size: 36px;
        code {
            color: #Cecece !important;
        }
    }
    .central-libras_content__text {
        margin-bottom: 30px;
        line-height: 1.625rem;
        
        h2 {
            border-top: 0.063rem solid #cecece;
            padding-top: 0.75rem ;
        }
    }
    a {
        margin: 0 auto;
    } 
}
</style>
<script>
import Button from '../Button.vue'
export default {
    name: "ComponentSectionsCentralDeLibras",
    props: {
        component: Object,
    },
    components: {
        Button
    },
    data() {
        return {
        api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
    methods: {
        setAttribute() {
            const link = document.querySelector('.central-libras_content__text p a');
            
            if (link) {
                link.setAttribute('target', '_blank')
            }
        }
    },
    mounted() {
        this.setAttribute();
    }
};
</script>
  