<template>
    <section class="atendimento_featured" :style="{ backgroundImage: component.background.data ? 'url(' + api_url  + component.background.data.attributes.url + ')' : '' }" aria-label="Atendimento Exclusivo Amil One">    
        <div class="container">            
            <h2 class="atendimento_featured__title">
                {{component.title}}
            </h2>                     
            <div class="atendimento_featured__description" v-html="component.description">
            </div>
            <div class="atendimento_featured__list"            
            ref="slider__wrapper">
                <swiper :breakpoints="swiperOptions.breakpoints" :center-insufficient-slides="true"  class="swipper-atendimento atendimento_featured___slider" ref="swiper" @slideChange="onSlideChange">
                    <swiper-slide v-for="component in atendimentos" :key="component.id"  ref="swiperSlide">                         
                        <BoxIcon :component="component" />  
                    </swiper-slide>
                </swiper>                        
            </div>            
            <div class="navigation-swiper" :class="{show : showArrows}">
                <button @click="prevSwiper('.swipper-atendimento')" class="prev-swiper-nav" :disabled="disabledPrev" aria-label="Botão Voltar Slider Atendimento Exclusivo Amil One">Prev</button>
            <button @click="nextSwiper('.swipper-atendimento')" class="next-swiper-nav" :disabled="disabledNext" aria-label="Botão Próximo Slider Atendimento Exclusivo Amil One">Next</button>                  
            </div>
            <div class="atendimento_featured__link" v-if="component.link">
                <a :href="component.link" class="button button--primary" aria-label="Ver mais atendimento">
                    {{component.link_text}}
                </a>
            </div>
        </div>
      </section>
</template>
<style lang="styl">
.atendimento_featured {
    padding: 120px 1px;
    background-position: center top;
    background-repeat: no-repeat;
    text-align:center;
    background-size: 100%;
    position: relative;
    .prev-swiper-nav {
        background: url(../../images/prev.svg);
        background-repeat: no-repeat;            
        width:30px;
        height:30px;
        border:0;
        text-indent:-9999px;
        margin:0 10px;
        &:disabled {
            opacity: .35;
        }
    }

    .next-swiper-nav {
        background: url(../../images/next.svg);
        background-repeat: no-repeat;                                
        border:0;
        width:30px;
        height:30px;
        text-indent:-9999px;
        margin:0 10px;
        &:disabled {
            opacity: .35;
        }
                
    }
    .swiper-button-disabled {
        opacity: .35;
    }
    .navigation-swiper {
        width:100%;
        text-align:center;        
        display:none;
        &.show{
            display:block;            
        }
    }
    @media $large-mobile {
        background-size: 100%;
        padding: 2px 0;
    }
    @media $large-mobile {
        background:#EFEFEF;
        background-image: none !important;
        padding: 2px 0 15% 0;
    }
    @media (min-width:2560px) {
        background-size:cover;
    }
    @media (max-width:1200px) {
        background-size: cover;
    }
    &::before {
        @media $large-mobile {
            content: '';
            display: inline-block;
            background: #EFEFEF;
            height: 159px;
            width: 105%;
            position: absolute;
            left: -10px;
            top: -127px;
            border-radius: 50% 50% 0 0;
        } 
    }       
    &::after {
        @media $large-mobile {
            content: '';
            display: inline-block;
            background: #EFEFEF;
            height: 159px;
            width: 105%;
            position: absolute;
            left: -10px;
            bottom: -58px;
            border-radius: 0 0 50% 50%;
        }        
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .atendimento_featured__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #D5086E;
        margin-bottom:40px;
        z-index: 1;
        @media $large-mobile {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 20px;
        }
    }
    .atendimento_featured__description {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 38px;
        margin-bottom: 30px;    
        @media $large-mobile  {
            font-size:18px;
            line-height: 32px;
        }    
    }
    .navigation {
        button {
            border: none;
            background: none;
            width: 30px;
        }
    }
    .atendimento_featured__list {
        overflow: hidden;
            width: 100%;
    }
    .atendimento_featured___slider {
        display:flex;
            justify-content:center;
            gap: 20px;
            margin:40px;
    }
    .atendimento_featured__link {
        font-family: 'Amil Typeface';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;        
        color:#D5086E;
        margin-top: 50px;
        padding-bottom: 30px;
        @media $mobile-max {
            margin-top: 50px;
        }
        a {
            &:hover {
                color:#D5086E;
            }
        }
        &:after {
            content: '';
            display: block;
            width: 100%;
            max-width:100%;
            height: 2px;
            background: #D5086E;
            margin: 10px auto;  
            position: relative;
            z-index: 100;          
        }   

    }    
}
</style>
<script>
import axios from 'axios';
import BoxIcon from '../BoxIcon.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
export default {
    name: "ComponentSectionsAtendimento",
    props: {
        component: Object,
    },
    components: {
        BoxIcon,
        Swiper,
        SwiperSlide
    },
    methods: {
        strapi_api_url: function () {
            return process.env.VUE_APP_STRAPI_API_URL;
        },
        getAtendimento: async function () {
            const api_url = this.strapi_api_url();
            let filters = "/atendimentos-exclusivos?sort[0]=rank";
            let atendimentos = await axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            const res = {
                atendimentos
            };
            this.atendimentos = atendimentos;
            return res;
        },        
        async setup() {
            const atendimentos = await this.getAtendimento();
            const styles = `
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            `;
            return {
                styles,
                atendimentos: atendimentos,
            };
        },            
        nextSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slideNext();            
        },
        prevSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slidePrev();
        },
        checkArrows() {
            const swiperEl = this.$refs.swiper;
           
            
            const swiperSlide = this.$refs.swiperSlide;
           
            const swiperElWidth = swiperEl.$el.clientWidth;
            // get swiper slide width
            const swiperSlideWidth = swiperSlide[0].$el.clientWidth;            
            const totalWidth = this.atendimentos.length * swiperSlideWidth;
            if(totalWidth > swiperElWidth) {
                this.showArrows = true;
            } else {
                this.showArrows = false;
            }
        },
        onSlideChange(swiper) {
        
            if(swiper.isBeginning) {
                this.disabledPrev = true;
            } else {
                this.disabledPrev = false;
            }
            if(swiper.isEnd) {
                this.disabledNext = true;
            } else {
                this.disabledNext = false;
            }
            
            // get swiper El width
        }   
                
    },
    async mounted() {
        const data = await this.setup();
        this.noticias = data.atendimentos;
        this.checkArrows();
        window.addEventListener('resize', this.checkArrows);                         
    },
    data() {
        return {
            atendimentos: {},
            styles: {},
            showArrows: false,
            disabledNext: false,
            disabledPrev: true,
            api_url: process.env.VUE_APP_STRAPI_URL,                
            swiperOptions: {
                breakpoints: {       
                    320: {       
                        slidesPerView: 1,
                        spaceBetween: 0,  
                    },
                    699: {       
                        slidesPerView: 2,       
                        spaceBetween: 0,   
                    },
                    990: {       
                        slidesPerView: 3,       
                        spaceBetween: 0 ,    
                    },
                    1100: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                    1194: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                },        
            }
        };
    },
}
    
</script>


