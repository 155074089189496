<template>
    <a :href="link" tabindex="0" class="btn-more" title="Botão de mais">
        {{text}}
    </a>        
</template>
<style lang="styl"> 
.btn-more {
    color: #fff;
    display: block;
    background:#D5086E;
    border-radius: 100px;    
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;  
    max-width: 195px;    
    text-align: center;
    text-transform: uppercase;    
    text-decoration: none;
    border: none !important; 
    color: #FFF; 
    &:hover {
        background: #f60d81;
        color: #fff;
    }
    @media $large-mobile {
      marign-top: 20px
    }
}
</style>
<script>
export default {
  name: "ButtonComponent",
  props: {
    link: String,
    text: String,
  },
  data() {
    return {
      categories: [],
    };
  }
};
</script>