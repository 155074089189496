<template>
    <div>
        <div v-if="component"> 

        <section class="banner">   
                     
            <div class="desk-version" 
                
                :class="{ dn: component.remove_banner}"
                :style="{background: 'url('+ api_url + component.image_desktop.data.attributes.url +')'}"
                :alt="component.image_desktop.data.attributes.alternativeText"></div>
            <div class="mobile-version" 
                :style="{background: 'url('+ api_url + component.image_mobile.data.attributes.url +')'}"
                :alt="component.image_mobile.data.attributes.alternativeText">
            </div>
            <div class="center-content">
                <div class="section-title">
                 
                    <h1 
                     :class="{ darkBlue: component.remove_banner}"
                    class="t-50 t-lh-77 t-sb t-white">                        
                        {{component.title}}
                    </h1>
                    <!--<ul
                     :class="{ darkBlue: component.remove_banner}" 
                    class="t-20 t-lh-28 t-white">
                        <li><a href="/">Home</a></li>
                        <li v-if="parent.data"><a :href="'/'+parent.data.attributes.slug">{{parent.data.attributes.title}}</a></li>
                        <li>{{component.title}}</li>         
                    </ul>-->
                </div>
            </div>
        </section>       
        </div>
    </div>    
</template>
<style lang="styl">
    .banner{
        display: flex;        
        justify-content: center;                
        position relative;

        .desk-version{
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            height: 44vh;
            max-height: 382px;
            position: absolute;
            width: 100%
            @media $mobile-max{
                display: none;
            }
        }

        .mobile-version{
            display: none;

            @media $mobile-max{
                display:block;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                height: 100%;
                max-height: 440px;
                position: absolute;
                width: 100%;
            }
        }

        .center-content{
            position: absolute !important;

             @media $mobile-max{
                bottom: 0px !important;
            }
        }

        .section-title{
            display: flex;
            flex-direction: column;
            height: 44vh;
            justify-content: flex-end;
            max-height: 382px;
            padding-bottom: 35px;

            h1{
                font-family: $amtpfc !important;
                font-size:50px;
                font-weight: 600;
                margin-bottom: 30px;

                @media $mobile-max{
                    line-height: 60px;
                    max-width: 160px;
                }
            }

            ul{
                align-items: flex-end;
                display: inline-flex;
                gap: 5px;

                li{
                    font-size:20px;
                    font-weight:600;
                }

                li:first-child:before{
                    content: none;
                }

                li:before{
                    display: inline-flex;
                    content: "-";
                    font-size: 16px;
                    font-weight: 600;
                    margin-right: 5px;
                }

                @media (max-width: $minSmallerContextPX ){
                    li{
                        font-size: 16px;
                    }
                }
            }
        }

    }
</style>
<script>
import axios from 'axios';
import { useRoute } from 'vue-router';
export default {
    name: "ComponentSectionsBanner",
    props: {
        component: Object,
    },
    async setup() {        
        let parent = '';
        const route = useRoute();
        const slug = route.params.slug;
        if( typeof slug !== 'undefined' ) {
            await axios.get(`${process.env.VUE_APP_STRAPI_API_URL}/pages/getBySlug/${slug}`).then(response => {         
               parent = response.data.data.attributes.parent_page;
               
            });               
            
        }
        return {
            parent: parent,
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
}
</script>
