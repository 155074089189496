<template>

    <section class="balloon_grouped">    
        <div class="container">
            
                <h2 class="balloon_featured__title" v-if="component.title">
                    {{component.title}}
                </h2>                     
                <div class="balloon_featured__description" v-html="component.description">

                </div>
            <div class="balloon_featured__list">
                <Balloon v-for="component in component.baloons" :key="component.id" :component="component" />               
            </div>
            <div class="balloon_featured__link">
                <a :href="component.link_url" class="button button--primary">
                    {{component.link_text}}
                </a>
            </div>
        </div>
      </section>
</template>
<style lang="styl" scoped>
    .balloon_grouped {
        padding:40px 0 80px 0;
        @media $mobile-max {
            padding: 40px 20px 80px 20px;
        }
    }
    .balloon_featured__link {
        margin:20px 0 0 0;
        display: flex;
        justify-content: center;
        @media $large-mobile {
            margin: 49px 0 0 0;
        }
        a {
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            color: #D5086E;
            &:after {
                content:'';
                display:block;
                width:120px;
                height:4px;
                background: #D5086E;
                margin-top:10px;
            }
        }

    }
</style>
<script>
import Balloon from "../Balloon.vue";

export default {
    name: "ComponentSectionsBalloons",
    props: {
        component: Object,
    },
    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
    components: { Balloon }
};
</script>
