<template>
    <section class="rede_credenciada_featured" :style="{ backgroundImage: component.bg.data ? 'url(' + api_url  + background + ')' : '' }">    
        <div class="container">        
            <h2 class="rede_credenciada_featured__title">
                {{component.title}}
            </h2>
            <div class="rede_credenciada_featured__description" v-html="component.description">
            </div>
            <swiper
                :breakpoints="swiperOptions.breakpoints" :centeredSlides="false" :navigation="true">
                <swiper-slide  v-for="rede_credenciada in rede" :key="rede_credenciada.id"> 
                    <div class="rede_credenciada__cidade">
                        <div class="rede_credenciada__cidade__title">                        
                            <h3 class="rede_credenciada_featured__initials">
                                {{rede_credenciada.attributes.state}}
                            </h3>
                        </div>
                        <ul class="rede_credenciada_featured__cidade__list">
                            <li v-for="rede_item in rede_credenciada.rede_credenciada" :key="rede_item.id" class="rede_credenciada__cidade__item">
                                <div class="rede_credenciada__cidade__item__title">                            
                                    {{rede_item.attributes.title}} 
                                    <!--<span class="asterisk" v-if="rede_item.attributes.exclusivo_s2500_s2600">*</span>-->
                                </div>
                            </li>                 
                        </ul>
                    </div>
                </swiper-slide>
            </swiper>            
            <div class="rede_credenciada_featured__link">
                <a :href="component.button_link" class="button button--primary">
                    {{component.button_text}}
                </a>
            </div>
        </div>
      </section>
</template>
<style lang="styl">
.rede_credenciada_featured__cidade__list {
    list-style-type: disc;
    padding-left: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-top: 30px;    
    li {
        margin-bottom: 17px;
    }
    @media $large-mobile {        
        margin: 30px 10px;        
    }
}
.rede_credenciada__cidade {
    width: auto;
    min-height:200px;
    padding:0 40px;
    margin-top: 20px;   
    text-align: left;
}
.rede_credenciada_featured__initials {
    text-align:left;
    color:#fff;
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
}
.rede_credenciada_featured {
    padding: 120px 0;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F6F6F6;
    text-align:center;
    color:#FFF;
    @media $large-mobile {
        padding: 5px 0;
    }
    @media $mobile-max {
        padding: 5px 0;
        height: 1107px;
        background-position: center top;
    }
    .swiper {
        @media $mobile-max {
            height: 445px !important;
        }
    }
    .swiper-slide {        
        border-left:1px solid #fff;

        margin-top: 50px;
        margin-bottom: 50px;        
        display: flex;
        justify-content: center;
        &:first-child {
            border-left: none;
        }
        @media $mobile-max {
            width: 286p !important;
        }
        @media (max-width: 1100px) {
            border-left:0;
        }
    }
    .swiper-wrapper {
        @media (min-width: 1100px) {
            display: flex !important;
            justify-content: center !important;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
        @media (max-width: 1100px) {
            display: block !important;
        }
    }
    .swiper-button-prev::before {
        background: url(../../images/prev-white.svg);
        background-repeat: no-repeat;
        content:"";
        display: inline-block;
        height: 50px;
        position: absolute;
        bottom: -16px;
        width: 32px;
        left: -1px;
    }

    .swiper-button-next::before {
        background: url(../../images/next-white.svg);
        background-repeat: no-repeat;
        content:"";
        display: inline-block;
        height: 32px;
        position: absolute;
        bottom: 4px;
        width: 50px;    
        right: -19px;
    }
    .swiper-button-next, .swiper-button-prev {
        color: #D5086E;
        display: block;
        &::after {
            display: none;
        }
    }    
}
.rede_credenciada_featured__description {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
    max-width: 885px;
    margin: 0 auto;
    @media $mobile-max {
        font-size: 18px;
    }    
}

.rede_credenciada_featured__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    margin:40px 0;
    @media $large-mobile {
        margin-top:187px;
    }
    
}

.rede_credenciada__cidade__title {
    @media $large-mobile {        
        margin: 0 30px; 
    }

}
.rede_credenciada_featured__link {
    font-family: 'Amil Typeface';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;  
    @media $large-mobile {
        display: none;
    } 
    a {
        color:#FFFFFF;
    }   
    /* identical to box height */
    &:after {
        content: '';
        display: block;
        width: 250px;
        max-width:100%;
        height: 4px;
        background: #D5086E;
        margin: 10px auto;            
    }

}


</style>
<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
import 'swiper/css/navigation'
import 'swiper/css';
export default {
    name: "ComponentSectionsSlider",
    props: {
        component: Object,
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        strapi_api_url: function () {
            return process.env.VUE_APP_STRAPI_API_URL;
        },
        getCidades: async function () {
            const api_url = this.strapi_api_url();
            let filters = "/cidades?pagination[limit]=3&sort[0]=order";
            let cidades = await axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            const res = cidades;
            
            return res;
        },
        getRedeCredenciada: async function (cidade) {
            const api_url = this.strapi_api_url();
            let filters = `/rede-credenciadas?filters[cidade][id]=${cidade.id}&sort[0]=rank`;
            let redeCredenciada = axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            return redeCredenciada;        
        },
        async setup() {
            const cidades = await this.getCidades();            
            const rede = [];
            var promises = [];
            cidades.forEach(element => {                
                promises.push( this.getRedeCredenciada(element).then((response) => {
                    element.rede_credenciada = response;                    
                    rede.push(element);
                }));                
            });
            return Promise.all(promises).then(() => {                            
                
                return {                    
                    cidades,
                    rede
                };                
            });
                    
        },
        backgroundImage() {
            let media = window.matchMedia('(max-width: 599px)' )
            if (media.matches) {
                this.background = this.component.bg_mobile.data.attributes.url
            } else {
                this.background = this.component.bg.data.attributes.url
            }
        }

    },
    async mounted() {
        const data = await this.setup();
        this.rede = data.rede;        
        this.backgroundImage();
        
    },
    data() {
        return {
            rede: {},
            styles: {},
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
            background: this.component.bg.data.attributes.url,
            swiperOptions: {
                navigation: true,
                breakpoints: {       
                    320: {       
                        slidesPerView: 1,
                        spaceBetween: 10     
                    },
                    699: {       
                        slidesPerView: 1,       
                        spaceBetween: 30     
                    },
                    990: {       
                        slidesPerView: 1,       
                        spaceBetween: 30     
                    },
                    1100: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    }
                },
        
            }
        };
    },
}
    
</script>


