<template>
    <a class="box-selection" v-if="component" :href="component.attributes.link" title="botão">
        <div class="box-selection__img_wrapper">
            <div class="icon-svg" v-html="component.attributes.icon"></div>
        </div>
        <div class="box-selection__text">
            <h3 class="box-selection__title" v-html="component.attributes.title"></h3>                
        </div> 
    </a>
</template>
<style lang="styl">     
    a {
        text-decoration: none !important;
    }
    .box-selection {
        cursor:pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 12px;
        gap: 16px;
        margin:0 20px;
        height: 161px;
        background: #FFFFFF;
        box-shadow: 5px 0 20px rgba(0, 0, 0, 0.09);
        border-radius: 16px;
        transition: all 0.2s ease-in-out;
        flex: none;
        order: 1;
        flex-grow: 0;
        @media (min-width:1260px) {
            max-width:150px;
        }
        &:hover {
            background:#D5086E;
            .box-selection__title {
                color:#FFF;
            }
            .box-selection__img_wrapper {
                svg {
                    rect {
                        fill:#FFF;
                    }
                }
            }
        }        
    }
    
    .box-selection__title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
    }
</style>
<script>
export default {
  name: "ComponentCard",
  props: {
    component: Object,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  },
};
</script>