<template>
  <section v-if="component" class="component-timeline" aria-label="linha do tempo Amil One">
    <div class="center-content">
      <h2 v-if="component.title">{{ component.title }}</h2>
      <div class="time-line">
        <div class="time-line__list" ref="slider__wrapper">
          <div class="time-line__slider" ref="slider">
            <div v-for="time in component.Timeline" :key="time.id" class="items-slider">
              <div class="icones" v-if="time.ano" @click="tabsnav" :data-id="time.ano" :class="{ active: time.active}">
                {{ time.ano }}
                <span :data-class="time.ano"></span>
              </div>
            </div>
          </div>
          <div class="navigation">
            <button @click="activePrevSlide">
              <img src="@/images/prev.svg" alt="slider anterior" />
            </button>
            <button @click="activeNextSlide">
              <img src="@/images/next.svg" alt="próximo slider" />
            </button>
          </div>
        </div>
        <article
          :id="content.ano"
          v-for="content in component.Timeline"
          :key="content.id"
          :class="{ block: content.active}"
        >
          <div class="content">
            <h3>{{ content.title }}</h3>
            <p v-html="content.text"></p>
            <Video 

              v-if="mounted"
              class="current-video"
              :width="913"
              :height="530"
              :url="formattedUrl(content.video)"
              
            />
          </div>
        </article>
      </div>
    </div>
  </section>
</template>
<style lang="styl" scoped>
.component-timeline {
background: #FFF;
padding:50px 0;
.center-content {
    h2 {
        text-align: center;
        font-weight: 500;
    }
    .time-line {
        gap: 133px;
        position:relative;
        text-align: center;
        margin-top:32px;
        .time-line__list {
            overflow: hidden;
            width: 100%;
        }
        .navigation {
            display: none;
            button {
                border: none;
                background: none;
                width: 30px;
                cursor: pointer;

            }
            @media $large-mobile {
                display: flex;
                justify-content: center;
            }
        }
        .time-line__slider {
            display:flex;
            justify-content:center;
            gap: 10px;
            @media $mobile-max {
                gap: 0;
            }
        }
        .items-slider {
            width: 100%;
            @media $large-mobile {
                width: 100%;
            }
        }
        .icones {
            position: relative;
            cursor: pointer;
            margin-top: 80px;
            top: 0;
            @media $large-mobile {
                width: 286px;
            }
            @media $mobile-max  {
                width: 221px;
            }
            &::before {
                content: '';
                display: inline-block;
                width: 38px;
                height: 38px;
                border: 2px solid #3B3B3A;
                border-radius: 50%;
                position: absolute;
                box-shadow: inset 0 0 0 3px #FFFFFF, inset 0 0 0 9px #FFFFFF;
                top: -65px;
                background: #3B3B3A;
            }
            span {
                border: 1px solid #3B3B3A;
                width: 100%;
                height: 0px;
                background: #FFFFFF;
                position:absolute;
                top: -47px;
                &[data-class="2010"] {
                    @media $mobile-max {
                        width: 50% !important;
                        right: 0 !important;
                    }
                }
                &[data-class="2023"] {
                    display: none;
                   @media $mobile-max {
                        width: 50% !important;
                        left: 0 !important;
                        display: block  !important;
                   }
                }
            }
            &.active {
                color: #D5086E;
                font-size: 24px;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 64px;
                    height: 64px;
                    border: 2px solid #D5086E;
                    border-radius: 50%;
                    position: absolute;
                    top: -77px;
                    box-shadow: inset 0 0 0 3px #FFFFFF, inset 0 0 0 15px #FFFFFF;
                    background: #D5086E;
                }
                span {
                    border: 1px solid #D5086E;
                    width: 100%;
                    height: 0px;
                    background: #FFFFFF;
                    position:absolute;
                    top: -47px;
                    z-index: -1;
                }
            }

            @media $mobile-max {
                display: flex;
                justify-content: center;
            }
        }
    }
    article {
        display: none;
        margin: 50px auto;
        animation: toTop 1s ease 0s 1 normal forwards;
       .content {
        padding: 10px;
            h3 {
                font-size:36px;
                font-weight: 500;
                line-height: 28px;
                text-align: center;
                color: #D5086E;
                @media $mobile-max {
                    font-size: 20px;
                    line-height:29px;
                }
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 38px;
                text-align: center;
                color: #3B3B3A;
                margin-top: 30px;
                margin-bottom: 50px;
                @media $mobile-max {
                    text-align: left;
                    font-size: 16px;
                    line-height: 32px;
                }
            }
       }
    }

}
}
iframe {
  @media $mobile-max {
      width: 312px !important;
      height: 177px !important;
  }
}

@keyframes toTop {
0% {
    opacity: 0;
    transform: translateY(-30px);
}

100% {
    opacity: 1;
    transform: translateY(0);
}
}
</style>
<script>
import Video from "../Video.vue";
export default {
  name: "ComponentSectionsTimeLine",
  props: {
    component: Object,
  },
  data() {
    return {
      mounted: false,
      api_url: process.env.VUE_APP_STRAPI_URL,
      open: false,
      dist: {
        finalPosition: 0,
        startX: 0,
        movement: 0,
      },
      touch: {
        startX: 0,
        endX: 0,
      },
    };
  },
  components: {
    Video,
  },
  methods: {
    tabsnav(event) {
      const video = document.querySelectorAll(".video_content .before-video");

      document.querySelectorAll(".icones").forEach((item) => { item.classList.remove("active"); });

      event.target.classList.add("active");

      document.querySelectorAll("article").forEach((item) => { item.style.display = "none"; });
      document.getElementById(`${event.target.dataset.id}`).style.display = "block";

    },
    formattedUrl(url) {
      if (!url) return;
      return url.replace("watch?v=", "embed/");
    },
    transition(active) {
      const slider = this.$refs.slider;
      slider.style.transition = active ? "transform .3s" : "";
    },
    moveSlide(distX) {
      this.dist.movePosition = distX;
      document.querySelector(
        ".time-line__slider"
      ).style.transform = `translate3d(${distX}px, 0, 0)`;
    },
    updatePosition(clientX) {
      this.dist.movement = (this.dist.startX - clientX) * 1.6;
      return this.dist.finalPosition - this.dist.movement;
    },
    onStart(event) {
      event.preventDefault();
      this.dist.startX = event.clientX;
      document
        .querySelector(".time-line__list")
        .addEventListener("mousemove", this.onMove);
      this.transition(false);
    },
    onMove(event) {
      const finalPosition = this.updatePosition(event.clientX);
      this.moveSlide(finalPosition);
    },
    onEnd(event) {
      
      document
        .querySelector(".time-line__list")
        .removeEventListener("mousemove", this.onMove);
      this.dist.finalPosition = this.dist.movePosition;
      this.transition(true);
      this.changeSlideOnEnd();
    },
    changeSlideOnEnd() {
      if (this.dist.movement > 120 && this.index.next !== undefined) {
        this.activeNextSlide();
      } else if (this.dist.movement < -120 && this.index.prev !== undefined) {
        this.activePrevSlide();
      } else {
        this.changeSlide(this.index.active);
      }
    },
    slidePosition(slide) {
      const wrapper = this.$refs.slider__wrapper;
      const margin = (wrapper.offsetWidth - slide.offsetWidth) / 2;
      return -(slide.offsetLeft - margin);
    },
    slidesConfig() {
      const slider = this.$refs.slider;
      this.slideArray = [...slider.children].map((element) => {
        const position = this.slidePosition(element);
        return { position, element };
      });
    },
    slideIndexNav(index) {
      const last = this.slideArray.length - 1;
      this.index = {
        prev: index ? index - 1 : undefined,
        active: index,
        next: index === last ? undefined : index + 1,
      };
    },
    changeSlide(index) {
      const activeSlide = this.slideArray[index];
      this.moveSlide(activeSlide.position);
      this.slideIndexNav(index);
      this.dist.finalPosition = activeSlide.position;
      this.changeActiveClass();
    },
    changeActiveClass() {
      this.slideArray.forEach((item) => item.element.classList.remove("active"));
      this.slideArray[this.index.active].element.classList.add("active");
    },
    activePrevSlide() {
      if (this.index.prev !== undefined) this.changeSlide(this.index.prev);
    },
    activeNextSlide() {
      if (this.index.next !== undefined) this.changeSlide(this.index.next);
    },
    touchstart(event) {
      this.touch.startX = event.touches[0].clientX;
      this.touch.endX = 0;
    },
    touchmove(event) {
      this.touch.endX = event.touches[0].clientX;
    },
    touchend() {
      if (!this.touch.endX || Math.abs(this.touch.endX - this.touch.startX) < 20) return;
      if (this.touch.endX < this.touch.startX) this.activeNextSlide();
      else this.activePrevSlide();
    },
  },
  mounted() {
    document.getElementById("2023").style.display = "block"
    const wrapper = this.$refs.slider__wrapper;
    wrapper.addEventListener("mousedown", this.onStart);
    wrapper.addEventListener("mouseup", this.onEnd);
    this.slidesConfig();
    this.changeSlide(4);
    this.transition(true);
    this.$el.addEventListener("touchstart", (event) => this.touchstart(event));
    this.$el.addEventListener("touchmove", (event) => this.touchmove(event));
    this.$el.addEventListener("touchend", () => this.touchend());
    this.mounted = true;
  },
};
</script>
