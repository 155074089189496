<template>
    <dl v-if="component">
        <dt
        :class="open ? 'transform' : ''"         
        @click="open = !open">
            {{ component.title }}                       
        </dt>
        <dd
        :class="open ? 'block' : 'dn'"
        v-html="component.text">
        </dd>
    </dl>               
</template>

<style lang="styl">
dl {
    margin-top: 30px;
    dt {
        padding: 32px;
        gap: 24px;
        color: #222;
        box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 20px;
        background: #FFF;
            &:after {
                background: url(../images/arrow.svg);
                background-repeat: no-repeat;
                background-position:center;
                content:"";
                display: flex;
                height: 11px;
                align-items: center;                
                width: 25px;
                transition: .4s;
                cursor: pointer;
            }
                    
    }  
    dt.transform { 
        background: #FFF;      
        border-radius: 16px 16px 0 0;
        border-top: 2px solid #D5086E;
        border-left: 2px solid #D5086E;
        border-right: 2px solid #D5086E;
            &:after {
            transform: rotate(180deg);
            top: -28px;
            transition: .4s;
        } 
    }   
    dd {
        padding: 28px 20px;
        font-size: 18px;
        line-height: 1.2;
        background: #fff;
        border-radius: 0 0 16px 16px;
        box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1);
        animation: toTop .3s ease-out;
        color: #3B3B3A;
        ul{
            li:before{
                background-color: $blue;
                border-radius: 50%;
                content:"";
                display: inline-flex;
                height: 11px;
                margin-right: 7px;
                width 11px;
            }
        }
        table {
            width:100%;
            tr:nth-child(odd) {
                background-color: #F2F2F2;                
            }
            tr {
                td:first-child {                    
                    color: #3B3B3A;
                    width:80%;                    
                }
                td:last-child {
                    
                    text-align: right;
                }
                td {
                    padding:15px 10px
                }
            }
        }
        &.block {
            border-bottom: 2px solid #D5086E;
            border-left: 2px solid #D5086E;
            border-right: 2px solid #D5086E;
            margin-top: -21px;
            margin-bottom: 20px;
        }
        @media $tablet-max {
            font-size:12px;
        }        
    }
    @keyframes toTop {
        0% {
            opacity: 0;
            transform: translateY(-15px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
} 
.dark_background {
    p {
        color: #3B3B3A;
    }
} 
.block {
    display: block;
}
.dn {
    display: none;
}
</style>
<script>
export default {
    name: "ComponentAccordion",
    props: {
        component: Object,
    },   
    data() {        
        return {    
            open: false                   
        };
             
    },
}
</script>