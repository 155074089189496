function getMetaTags (vm) {
 
  const { metaTags } = vm.$options;  
  if (metaTags) {
    
    return typeof metaTags === 'function'
      ? metaTags.call(vm)
      : metaTags
  }
}

function getTitle (vm) {
  const { title } = vm.$options    
  if (title) {
    return typeof title === 'function'
      ? title.call(vm)
      : title
  }
}

function getDescription (vm) {
  const { description } = vm.$options
  if (description) {
    return typeof description === 'function'
      ? description.call(vm)
      : description
  }
}

function getImage (vm) {
  const { image } = vm.$options
  if (image) {
    return typeof image === 'function'
      ? image.call(vm)
      : image
  }
}

function getUrl (vm) {
  const { url } = vm.$options
  if (url) {
    return typeof url === 'function'
      ? url.call(vm)
      : url
  }
}
export default {
  created() {
    const metaTags = getMetaTags(this);
    
    if (metaTags) {
      let actualMetaTags = document.head.querySelectorAll('meta');
      const metaTagsArray = Array.from(actualMetaTags);                
      metaTags.forEach(metaTag => {
          const meta = document.createElement('meta');
          meta.setAttribute('name', metaTag.name);
          meta.setAttribute('content', metaTag.content);
          //if metatagsarray contains the same name and content, then don't add it again
          
          if (!metaTagsArray.some(metaTag => metaTag.getAttribute('name') === meta.getAttribute('name') && metaTag.getAttribute('content') === meta.getAttribute('content'))) {
            const head = document.head;
            head.appendChild(meta);
              //document.head.insertAdjacentHTML('afterbegin', meta);
          }
      });
      const title = getTitle(this);
      const description = getDescription(this);
      const image = getImage(this);
      const url = getUrl(this);
      //prevent duplicate meta tags
      const getCurrentMeta = document.head.querySelectorAll('meta');
      const getCurrentMetaArray = Array.from(getCurrentMeta);      
      if (!getCurrentMetaArray.some(metaTag => metaTag.getAttribute('property') === 'og:title')) {
        
        this.setOgTags(title, description, image, url);
      }      
    }
  },
  methods: {
    setOgTags(title, description, image, url) {
      const meta = document.createElement('meta');
      meta.setAttribute('property', 'og:title');
      meta.setAttribute('content', title);
      const head = document.head;
      head.appendChild(meta);
      if(description === null || description === undefined || description === ''){
        description = title;
      }
      const meta2 = document.createElement('meta');
      meta2.setAttribute('property', 'og:description');
      meta2.setAttribute('content', description);
      head.appendChild(meta2);
      

      if(image === null || image === undefined || image === ''){
        image = "http://localhost:8080/favicon.png";
      }
      const meta3 = document.createElement('meta');
      meta3.setAttribute('property', 'og:image');
      meta3.setAttribute('content', image);
      head.appendChild(meta3);
      
      // if(url === null || url === undefined || url === ''){
      //   url = "https://www.amilone.com.br";
      // }
      const meta4 = document.createElement('meta');
      meta4.setAttribute('property', 'og:url');
      meta4.setAttribute('content', window.location.href);
      head.appendChild(meta4);
    }
  }
}