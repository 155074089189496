<template>
    <div class="component-faq" v-if="component">        
        <div class="container">
            <div class="faq-header">
                <h2 class="title-faq">{{ component.title }}</h2>
                <div class="description-faq" v-html="component.description"></div>
                <div v-if="component.show_search" class="search">
                    <form action="#" @submit.prevent="searchDuvidas()" class="input-search">
                        <input type="text" placeholder="Pesquisar" id="search"  @blur="searchDuvidas()"/>
                        <button class="btn-pesquisar">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.4618 15.893C16.0713 15.5024 15.4381 15.5024 15.0476 15.8929C14.657 16.2833 14.657 16.9165 15.0475 17.3071L16.4618 15.893ZM21.2955 23.556C21.686 23.9465 22.3191 23.9466 22.7097 23.5561C23.1002 23.1656 23.1003 22.5324 22.7098 22.1418L21.2955 23.556ZM13.6002 19.2383C18.2032 17.2821 20.3489 11.9647 18.3926 7.36168L16.552 8.14395C18.0762 11.7304 16.4044 15.8734 12.8179 17.3977L13.6002 19.2383ZM18.3926 7.36168C16.4364 2.75864 11.119 0.612988 6.51598 2.56923L7.29824 4.4099C10.8847 2.88569 15.0277 4.55748 16.552 8.14395L18.3926 7.36168ZM6.51598 2.56923C1.91294 4.52548 -0.232718 9.84284 1.72353 14.4459L3.5642 13.6636C2.03998 10.0771 3.71177 5.93412 7.29824 4.4099L6.51598 2.56923ZM1.72353 14.4459C3.67977 19.0489 8.99713 21.1946 13.6002 19.2383L12.8179 17.3977C9.23144 18.9219 5.08841 17.2501 3.5642 13.6636L1.72353 14.4459ZM15.0475 17.3071L21.2955 23.556L22.7098 22.1418L16.4618 15.893L15.0475 17.3071Z" fill="#D5086E"/>
                            </svg>
                        </button>
                    </form>
                    <p>Ou escolha a categoria do assunto:</p>
                </div>
                <div class="faq-categories">                
                    <swiper
                        :breakpoints="swiperOptions.breakpoints" :centeredSlides="false" :parameters="swiperOptions"  v-if="categories.length > 0" class="swiper-faq">
                        <swiper-slide v-for="category in categories" :key="category.id" >                                            
                            <div class="icon-category-faq" :data-category-faq="category.id" @click="filterDuvidas(category)">                                                    
                                <div class="icon-svg" v-html="category.attributes.icon"></div>
                                <p class="category-title">{{category.attributes.categoria}}</p>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="navigation-swiper">
                    <button @click="prevSwiper('.swiper-faq')" class="prev-swiper-nav">Prev</button>
                    <button @click="nextSwiper('.swiper-faq')" class="next-swiper-nav">Next</button>
                    
                </div>
            </div>                                
            <div class="faq-list" ref="faqList">
                <div class="faq-featured"> 
                    <Balloon :component="featured"></Balloon>
                    <div class="mini-banner">
                        <img :src="api_url+component.mini_banner.data.attributes.url" :alt="component.alternativeText" >
                    </div>
                </div>
                
                <div class="faq-pages">                    
                    <div class="duvidas">                        
                        <div class="faq-featured__item" v-for="faq in duvidas" :key="faq.id">
                            <Accordion :component="faq" />
                        </div>
                    </div>                    
                </div>
                
            </div>
        </div> 
    </div>
</template>
<style lang="styl"> 

.title-faq {
    font-family: Amil Typeface;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: #D5086E;

}
.description-faq {    
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;    
    margin-bottom: 40px;
    text-align:center;
    max-width:860px;
    margin:0 auto;
    width:80%;
    a {
        color:#D5086E;
        font-weight:bold;
    }
}
.faq-featured__item a {
    color: #D5086E;
    font-weight:bold;
}
.faq-featured__item table,
.faq-featured__item table td {
    border:1px solid #ccc;
}

.faq-featured__item table th {
    text-align: left;
    padding:10px;    
}

.faq-featured__item table th,
.faq-featured__item table td{    
    width: 20%; 
    text-align: left;
}

.faq-featured__item,
.duvidas {
    max-width: 100%; 
}
.faq-pages {
    max-width: 100%;
}
.faq-featured__item dd {
    overflow-x: auto;
}

.input-search {
    margin:0 auto;
    height: 65.61090087890625px;
    width: 750px;
    left: 345px;
    top: 301.81005859375px;
    border-radius: 100px;
    font-family: Amil Typeface;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    max-width:80%;
    border: 2px solid #ABABAB;
    background:#fff;    
    display:flex;
    padding:0 20px;
    margin-bottom:40px;
    input {
        flex-grow:1;
        height:100%;
        border:none;
        background:transparent;
        min-width:10px
    }
    button {
        flex-shrink:0;
        background:transparent;
        border:none;
    }
}
.component-faq {
    position: relative;
    padding:70px 0;
    .search {
        text-align:center;        
    }
    &:before {
        content:'';
        position: absolute;
        width: 3322px;
        height: 3322px;
        z-index:-1;
        left:50%;
        transform:translate(-50%,-95%);

        background: #F6F6F6;
        border-radius: 3000px;
    }
    .container {
        position: relative;
        z-index:1;
    }
    .mini-banner {
        margin-top:40px;
        @media $large-mobile {
            width: 100%;
        }
    }
    .icon-category-faq {
        
        display:flex;
        flex-direction:column;
        background: #fff;
        height: 161px;
        width: 146px;        
        border-radius: 16px;
        padding: 24px 12px 24px 12px;
        justify-content:center;
        align-items:center;                        
        text-align:center;
        gap:30px;
        cursor:pointer;
        &.active,
        &:hover {
            background-color: #D5086E;
            color:#fff;
            svg {
                g {
                  rect {   
                    fill:#fff;
                  }
                }
            }
        }
        .icon-image {
            width:40px;
            height:40px;
        }
        .category-title {
            font-family: Amil Typeface;
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            padding:0;
            margin:0;

        }
    }
    .title-duvidas {
        font-family: 'Amil Typeface';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 23px;
        /* cinza one */
        color: #3B3B3A;
        margin:40px 0;
    }
    .prev-swiper-nav {
        background: url(../../images/prev.svg);
        background-repeat: no-repeat;            
        width:30px;
        height:30px;
        border:0;
        text-indent:-9999px;
        margin:0 10px;
    }

    .next-swiper-nav {
        background: url(../../images/next.svg);
        background-repeat: no-repeat;                                
        border:0;
        width:30px;
        height:30px;
        text-indent:-9999px;
        margin:0 10px;
                
    }
    .navigation-swiper {
        width:100%;
        text-align:center;
        margin-bottom:50px;
        display:none;
        @media (max-width:1262px){
            display:block;
        }
    }
}  

.faq-categories {
    display:flex;
    justify-content:center;
    gap:30px;
    margin:40px 0;
    .swiper-button-prev::before {
        background: url(../../images/prev.svg);
        background-repeat: no-repeat;
        content:"";
        display: inline-block;
        height: 50px;
        position: absolute;
        bottom: -16px;
        width: 32px;
        left: -1px;
    }

    .swiper-button-next::before {
        background: url(../../images/next.svg);
        background-repeat: no-repeat;
        content:"";
        display: inline-block;
        height: 32px;
        position: absolute;
        bottom: 4px;
        width: 50px;    
        right: -19px;
    }
    .swiper-button-next, .swiper-button-prev {
        color: #D5086E;
        display: block;
        &::after {
            display: none;
        }
    } 
} 
.faq-list {
    margin-top:80px;
    display:flex;
    gap:40px;
    @media $large-mobile {
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }
}
.faq-featured {
    max-width: 30%;
    @media $large-mobile {
        max-width: 100%;
        padding: 20px;
    }
    .balloon-question {
        .title-balloon {
            font-size:18px;
            line-height:1.5em;
        }
    }
    .balloon-answer {
        font-size:16px;
        line-height:1.5em;
    }
    .balloon-date {
        margin-top:30px;
    }
    
    
}

.faq-pages {
    flex-grow:1;
    @media $large-mobile {
        padding: 20px;
    }
    .duvidas {
        width:100%;
    }
    dl {
        width:100%;
    }
    dt {
        @media $mobile-max {
            font-size: 16px;
        }
    }

}

.faq-featured__item {    
    .table {
        table {
        border:0;
        border-radius:20px;
        thead {
            border:0;                     
            overflow: hidden;
            tr {
                 &:first-child {
                    border-radius:20px 20px 0 0;
                }
            }
            th {
                &:first-child {
                    border-radius:20px 0 0 0;
                }
                &:last-child {
                    border-radius:0 20px  0 0;
                }
                border:none;
                background:#BA0760;
                color:#fff;
                text-align: center;
                width:20%;
                max-width: 20%;
                font-size:14.79px;
                line-height:17.6px;
            }
        }
        tr {
            &:last-child {
                border-radius: 0 0 0 20px;                    
                td:first-child {
                    border-radius: 0 0 0 20px;                    
                }
                td:last-child {
                    border-radius: 0 0 20px 0;                    
                }
            }
            border:0;
            td:first-child {
                width:20px;
                
            }
        }
        tr td {
            border:none;
            text-align: center;
            color:#000;
            font-weight: 400;
            font-size:14.79px;
            line-height:17.6px;

        }
    }
    }
}

</style>
<script>
import axios from 'axios';
import Accordion from '../Accordion.vue';
import Balloon from '../Balloon.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
import 'swiper/css/navigation'
import 'swiper/css';
export default {
    name: "ComponentSectionsFAQ",    
    props: {
        component: Object
    },
    components: {
        Accordion,
        Balloon,
        Swiper,
        SwiperSlide
    },
    data() {     
           
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
            categories: [],
            duvidas: [],
            featured: {},
            activeCategory: '',
            swiperOptions: {
                navigation: true,
                breakpoints: {       
                    320: {       
                        slidesPerView: 2,
                        spaceBetween: 10,    
                    },
                    600: {       
                        slidesPerView: 4,       
                        spaceBetween: 10,  
                    },
                    990: {       
                        slidesPerView: 5,       
                        spaceBetween: 10,   
                    },
                    1100: {
                        slidesPerView: this.categories && this.categories.length < 6 ? this.categories.length : 6,
                        spaceBetween: 20
                    }
                },
        
            }
        };
    },    
    async mounted() {
        const data = await this.setup();
    },
    
    methods: {
        nextSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slideNext();            
        },
        prevSwiper(swiper) {
            const swiperEl = document.querySelector(swiper);
            swiperEl.swiper.slidePrev();            
        },
        async setup() {
            
            const categories = this.getCategories();
            categories.then((response) => {                
                this.categories = response;            
            });
            
            const duvidas = this.getDuvidas();
            duvidas.then((response) => {                
                response.forEach(element => {
                    const component = {
                        title: element.attributes.title,
                        text: element.attributes.text
                    }
                    this.duvidas.push(component);
                });
                
            });            
            const featured =  {
                title: this.$props.component.featured_faq.data.attributes.title,
                description: this.$props.component.featured_faq.data.attributes.text,
                date:  this.$props.component.featured_faq.data.attributes.createdAt                                
            }            
            this.featured = featured;                            
        },  
        strapi_api_url: function () {
            return process.env.VUE_APP_STRAPI_API_URL;
        },
        filterDuvidas: function(category) {
            const oldCategory = this.activeCategory; 
            this.activeCategory = category;
                        
            //select all elements with .icon-category-faq and remove .active class
            const elements = document.querySelectorAll('.icon-category-faq');
            elements.forEach(element => {
                element.classList.remove('active');
            });
            if ( oldCategory === category ) {
                this.activeCategory = '';
                const duvidas = this.getDuvidas();
                this.duvidas = [];
                duvidas.then((response) => {                
                    response.forEach(element => {
                        const component = {
                            title: element.attributes.title,
                            text: element.attributes.text
                        }
                        this.duvidas.push(component);
                    });
                    
                });
                return false;
            }
            const item = document.querySelector('.faq-pages').getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo({
                top: item,
                behavior: 'smooth'
            });
            // get element by attr data-category-faq
            const element = document.querySelector(`[data-category-faq="${category.id}"]`);
            element.classList.add('active');            
            const duvidas = this.getDuvidas(category);
            duvidas.then((response) => {                                
                this.duvidas = [];
                response.forEach(element => {
                    const component = {
                        title: element.attributes.title,
                        text: element.attributes.text
                    }
                    this.duvidas.push(component);
                });
                
            });            
        },
        searchDuvidas: function() {
            const kw = document.querySelector('#search').value;
            const duvidas = this.searchApi(kw);
            duvidas.then((response) => {                                               
                this.duvidas = [];
                response.data.data.forEach(element => {
                    const component = {
                        title: element.attributes.title,
                        text: element.attributes.text
                    }
                    this.duvidas.push(component);
                });                
            });    
            //get list of categories and remove .active class
            const elements = document.querySelectorAll('.icon-category-faq');
            elements.forEach(element => {
                element.classList.remove('active');
            });

            const item = document.querySelector('.faq-pages').getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo({
                top: item,
                behavior: 'smooth'
            });
            
        }, 
        searchApi: function(kw) {
            const url = `${this.strapi_api_url()}/faqs?filters[$or][0][title][$containsi]=${kw}&filters[$or][1][text][$containsi]=${kw}&sort[0]=rank`;
            return axios.get(url);
        },  
        getCategories: async function () {
            const api_url = this.strapi_api_url();
            let filters = "/categorias-de-duvidas?pagination[limit]=9999&sort[0]=rank&populate=*";
            let categories = await axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            const res = categories;
            
            return res;
        },
        getDuvidas: async function (categoria) {
            const api_url = this.strapi_api_url();
            let filters = `/faqs?sort[0]=rank&populate=*`;            
            if(categoria) {
                filters = `/faqs?filters[categorias_de_duvida][id]=${categoria.id}&sort[0]=rank&populate=*`;                
            }
            
            let duvidas = axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            return duvidas;        
        },
        getDuvida: async function (id) {
            const api_url = this.strapi_api_url();
            let filters = `/faqs/${id}?populate=*`;
            let duvida = axios.get(`${api_url}${filters}`).then(response => {
                return response.data.data;
            });
            return duvida;        
        },
    }
}
</script>


