<template>
    <section class="component-banners" v-if="component">
        <div class="banners" ref="wrapperbanners">
            <picture>
                <source v-if="component.bg_mobile.data" :srcset="api_url + component.bg_mobile.data.attributes.url" media="(max-width:991px)">
                <img v-if="component.image.data" :src="api_url + component.image.data.attributes.url" alt="component.alternativeText" class="banners__image_bg" ref="image_bg" />
            </picture>
            <img v-if="!component.svg_element && component.bg_element.data" :src="api_url + component.bg_element.data.attributes.url" :alt="component.bg_element.data.attributes.alternativeText" class="banners__element_bg">
            <div v-if="component.svg_element" class="banners__element_bg banners__element_svg" v-html="component.svg_element"></div>

            <div class="container" :class="{ text_white: component.text_white }">
                <nav class="breadcrumb">
                    <ul class="t-20 t-lh-28">
                        <li aria-label="Página Inicial"><a href="/">Home</a></li>
                        <li class="divider"></li>
                        <li class="current_link" aria-current="página atual" v-if="component.breadcrumb">{{ component.breadcrumb }}</li>
                    </ul>
                </nav>
                <div class="banners__text" :class="{ text_white: component.text_white }">
                    <h2 class="banners__title" v-html="component.title"></h2>
                    <div class="banners___text_banners" v-html="component.text">
                    </div>
                    <a v-if="component.button_link || component.button_text" class="banners__link" :href="component.button_link" :target="link(component.button_link)">
                        {{ component.button_text }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>
<style lang="styl">
.component-banners {
    .banners {  
    background:#fff; 
    margin: 0;  
    position: relative;
    overflow: hidden;    
    @media $large-mobile {
        height: auto;
        padding:0 5% 100px 5%;
        margin:0 auto;
        
    }
    @media $mobile-max {
        margin-bottom: 0;
    }
   
    ul {
        display: flex;
        gap: 7px;
    }       
    .container {       
        position:relative;
        z-index:10;
        height:100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        @media $large-mobile {            
            margin-bottom: 30px;
            height: auto;
            
        }
        ul{
            z-index: 999999;
            padding: 40px 0 0 0;
            li {
                margin-bottom: 0 !important;
                line-height: 0 !important;
            }
            a {
                &:hover {
                    color: #D5086E;
                }
            }
            .divider {
                display: flex;
                &:before {
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 2px;
                    background: #3b3b3a;

                }
            }
            .current_link {
                color: #D5086E;
                font-weight: bold;
            }
        }         
    }
    .breadcrumb {
        width:100%;
        li {
            margin-bottom:0;
        }
        @media $large-mobile {
            margin-bottom:15px;
        }
    }
    .banners__title {
        font-weight: 400;
        font-size: 40px;
        line-height: 42px;
        @media $large-mobile {
            text-align: center;
            font-size: 28px;
            line-height: 29px;
        } 
        @media $mobile-max {
            text-align: left;
        }           
    }
    .banners__text {            
        display: flex;            
        max-width: 45%;
        justify-content: center;            
        flex-direction: column;            
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        flex-grow:1;
        @media $large-mobile {
            padding: 0;
            max-width: 100%;               
            text-align: center;
            display: flex;
            font-size: 16px;
        }
        @media $mobile-max {
            text-align: left;
        }
    }
    .banners___text_banners {
        margin-top:40px;
        font-size: 18px;
        line-height: 32px;
        @media $large-mobile {
            font-size: 18px;
        }
        @media $mobile-max {
            margin-bottom: 30px;
        }
        a {
            color: #D5086E;            
            text-decoration:none;
        }
    }
    .banners__link {
        background: #D5086E;
        padding: 18px 24px;
        width: 264px;
        height: 55px;
        border-radius: 200px;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        margin-top: 50px;
        display: flex;
        align-items: center;
        &:hover {
            background: #f60d81;
        }
        @media $large-mobile {
            margin: 30px auto;
        }
        @media $mobile-max {
            margin: 0;
        }
    }
    }        
    .banners__image_bg {
        position:absolute;
        top:0;
        right:0;
        z-index:0;
        width: 65%;
        height: auto;
        @media $large-mobile {
            width: 100%;
            height: 75%;
            bottom: 120px;
            position: absolute;
            left:0;
            object-fit: cover;   
        }
    }

    .banners__element_bg {
        position:absolute;
        left:0;
        top:0;
        z-index: 1;
        width: 80%;
        height:auto;        
        @media $large-mobile {
            display: none;
        }
    }
    .banners__element_svg svg {
        width: 100%;
        height: auto;     
        path {
            animation: rotatingBanner 6s infinite;
            transform-box: fill-box;
            transform-origin: 50%;
        }    
        image {
            animation: rotatingBanner 5s infinite ease-in-out;
            animation-direction: reverse;
            transform-box: fill-box;
            transform-origin: 50%;
        }
    }
}


@keyframes rotatingBanner {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
    
</style>
<script>
import axios from 'axios';
import { useRoute } from 'vue-router';
export default {

    name: "ComponentSectionsBanners",
    props: {
        component: Object
    },

    data() {
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
    mounted() {
        this.calculateImageSize();
        //on resize calculate image size again
        window.addEventListener('resize', this.calculateImageSize);
        this.parent = parent;
    },
    methods: {
        calculateImageSize() {
            //get image bg size
            //get viewport width
            let viewportWidth = window.innerWidth;
            if (viewportWidth > 768) {
                const image_bg = this.$refs.image_bg;
                const image = new Image();
                image.src = image_bg.src;
                image.onload = () => {
                    const image_bg_width = image.naturalWidth;
                    const image_bg_height = image.naturalHeight;
                    const wrapper = this.$refs.wrapperbanners;
                    const proportion = image_bg_height / image_bg_width;

                    const document_width = document.documentElement.clientWidth;
                    const new_image_width = document_width * 0.65;
                    const new_image_height = new_image_width * proportion;
                    new_image_height > 768 ? wrapper.style.height = '768px' : wrapper.style.height = new_image_height + 'px';
                }
            } else {
                const wrapper = this.$refs.wrapperbanners;
                wrapper.style.height = 'auto';
            }

        },
        link(link) {
            /* check if link is internal or external, if external renturn  _blank, else return _self */
            if (link.includes('http')) {
                return '_blank'
            } else {
                return '_self'
            }
        },

    },
}
</script>
