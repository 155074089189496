<template>
    <div class="text-without-imagem" v-if="(component || component.bg_color)" :class="{ bg_white: component.bg_color }">
        <div class="container" ref="wrapperParagraph">
            <h2 v-if="component.title">{{ component.title}}</h2>
            <div v-if="component.text" v-html="images(component.text)" class="text"></div>
            <div v-if="component.text_featured" v-html="component.text_featured" class="text_featured"
                 :style="{backgroundColor: component.bg ? component.bg : '',
                 color: component.color ? component.color: '',
                 margin: component.margin_featured ? component.margin_featured : '30px 0 0 0' }">

            </div>
          <div class="compartilhamento-mob">
            <ComponentSectionsShareSocialMedia></ComponentSectionsShareSocialMedia>
          </div>

        </div>
    </div>    
</template>
<style lang="styl">

  .compartilhamento-mob{
    display: none;
  }

.text-without-imagem {    
    padding:20px 0;
    @media $mobile-max {
        padding: 30px 16px;
    }
      @media $large-mobile {
        .compartilhamento-mob {
          display: block;
        }
      }
    h2 {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        color: #3B3B3A;
        margin-bottom: 20px;
        @media $large-mobile {
            margin-top: 60px;
        }
        @media $mobile-max  {
            margin-top: 0;
            font-size:22px;
            line-height:32px;            
        }
    }
    .text {
        font-weight: 400;
        font-size: 22px;
        line-height: 38px;
        color: #3B3B3A;
        @media $mobile-max  {
            font-size:14px;
            line-height: 28px;
        }
        ul{
            display: flex;
            flex-direction: column;
            li {
                padding-left: 20px;
                font-weight: 400;
                font-size: 22px;
                display: flex;
                align-items: center;
                display: block;
                margin-bottom: 0;
                line-height: 45px;
                @media $mobile-max  {
                    font-size:14px;
                    line-height: 28px;
                }
                &::before{
                        background-color: #3B3B3A;
                        border-radius: 50%;
                        content:"";
                        display: inline-flex;
                        height: 5px;
                        margin-right: 9px;
                        width: 5px;

                    }
                span {
                        display: inline;
                    }        
                }
              
            }        
        ul.list-cities{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap:20px;
            @media $large-mobile {
                grid-template-columns: repeat(2, 1fr);
            }
            @media $mobile-max {
                grid-template-columns: 1fr;               
            }
            li {
                list-style-type: none;
                background:#fff;    
                border: 2px solid #D5086E;
                border-radius: 16px;
                padding: 24px 16px;
                font-size:18px;
                line-height: 27px;
                font-weight:500;
                &:before {
                    display: none;
                    font-size: 18px;
                    line-height: 150%;
                }        
            }
        }
        
    }
    .text_featured {
        padding: 12px 16px;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 28px;        
        background: #F6F6F6;
        strong,
        a {
            color:#D5086E;
            text-decoration:none;
        }
    }

}
a {
    display: inline;
}
.bg_white {
    background: #FFFF;
}
.wrap-table {
    display:flex;
    max-width:100%;
    overflow-x: auto;    
}

.arraste {
    display:none;
    background:#ECECEC;
    color:#fff;
    border-radius:16px;    
    padding:15px;
    align-items: center;
    justify-items: center;
    gap:15px;
    margin-bottom:40px;
    color:#3B3B3A;
    @media $mobile-max {
        display:flex;
    }
}

.wrap-table {
    position:relative;
    table.contrate-agora {  
        tr:not(.pre-header) th:nth-child(1),    
        tr:not(.pre-header) td:nth-child(1)
        {        
            position: sticky;                                                 
            width:100px;                                    
        }
        tr:not(.pre-header) td:nth-child(1),
        tr:not(.pre-header) th:nth-child(1) {
            left:0;
        }
        
        tr:not(.pre-header) td:nth-child(1) {
            background:#F6F6F6;
                z-index:100;
        }
    }
}
table.contrate-agora {    
        border-collapse:separate;
        border-left:1px solid #D5086E;
        border-radius:40px;      
        
        
    tr {
        background:#E8E3E5;
        text-align: center;
        td {
            border:1px solid #fff;
            padding:15px;
        }
        th {
            padding:20px;
            border:1px solid #ffF;
            background:#3B3B3A;
            color:#fff;
            vertical-align: middle;
            &:nth-child(2),
            &:nth-child(8)
            {
                background:#D5086E;
            }
        }
        &:nth-child(odd) {
            td:nth-child(2),
            td:nth-child(8) {
            
                background:#FCCEE5;
            }
        }
        &:nth-child(even) {
            td:nth-child(2),
            td:nth-child(8){
                padding:20px;                
                background:#F2B5D4;
            }
        }
        &:nth-child(even) {
            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6),
            td:nth-child(7),
            td:nth-child(9){
                padding:20px;                
                background:#D4D2D3;                
            }
        }
        & {
            td:nth-child(4),
            td:nth-child(5),
            td:nth-child(6),
            td:nth-child(7),
            td:nth-child(10) {
                svg {
                    filter: grayscale(1);
                }
            }
        }
    }
    tr:first-child {
        background:transparent;
       
        th:first-child {
            border-left:1px solid #D5086E;
            border-top:1px solid #D5086E;                                    
            border-radius:40px 0 0 0;
        }
        th:last-child {
            border-right:1px solid #D5086E;
            border-top:1px solid #D5086E;                                    
            border-radius:0 40px  0 0;
        }
        th {
            border-top:1px solid #D5086E;                                    
        }
    }
    tr {
        td:first-child {
            border-left:1px solid #D5086E;
        }
        td:last-child {
            border-right:1px solid #D5086E;
        }
    }
    tr:last-child {
        background:transparent;
        td {
            border-bottom:1px solid #D5086E;
            background:#F6F6F6;
        }
        td:first-child {
            border-radius:0  0 0 40px;
        }
        td:last-child {
            border-radius:0  0 40px 0;
            border-right:1px solid #D5086E;
        }
        
    }
    @media (max-width:680px) {        
        font-size: 12px;
        line-height:130%;
    }
}

table.contrate-agora-planos {
    font-size: 10.3851px;
    line-height: 130%;
    border:0;
    tr:nth-child(2) {
        background:transparent;
        th:first-child {
            border-left:1px solid #D5086E;
            border-top:1px solid #D5086E;                                    
            border-radius:40px 0 0 0;
        }
        th:last-child {
            border-right:1px solid #D5086E;
            border-top:1px solid #D5086E;                                    
            border-radius:0 40px  0 0;
        }
        th {
            border-top:1px solid #D5086E;  
            font-size:14px;                                  
        }
    }
    tr.pre-header {
        td:first-child {
            border:0
        }
        font-size:17px;
        
    }
    thead {
        position:relative;
        &:before {
                    content:'';
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background:#fff;
                    z-index:-1;
        }
        tr.pre-header {    
            td {
                background:#D5086E !important;
                color:#fff;
                border:4px solid #fff;
                border-radius:40px;
                vertical-align:middle;
            }
            td:first-child {
                background:transparent !important;
            }
        }
    }

}



</style>
<script>

import ComponentSectionsShareSocialMedia from "@/components/dynamics/ComponentSectionsShareSocialMedia";

export default {
   
    name: "ComponentSectionsParagraph",    
    props: {
        component: Object
    },
  components:{
    ComponentSectionsShareSocialMedia
  },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
    
    methods: {
        images: function (value) {

            if (!value) return ''
            value = value.toString();
            //add api_url on image src start
            
            value = value.replace(/src="\//g, 'src="' + this.api_url + '/');
            
            
            return value                
        },
        link() {
            const links = document.querySelectorAll('.text p a');
            links.forEach(link => { link.setAttribute('target', '_blank')});
        }
    },
    mounted() {
        this.link();
    }
}
</script>


