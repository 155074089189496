<template>
    <div>
        <div v-if="component">
            <section class="ona" :class="{ bg_lightGrey : component.gray_background }"  >
                <div class="center-content">
                    <img class="ona-picture" :src="api_url + component.image.data.attributes.url">
                    <div class="text-content">
                        <h2 class="t-34 t-lh-55 t-darkBlue t-black">{{component.title}}</h2>
                        <p class="t-18 t-lh-28">{{component.text}}</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style lang="styl">
    .ona{
        padding: 118px 0px;

        .center-content{
            align-items: center;
            display: flex;

            @media $large-mobile{
                display: block;
                text-align: center;
            }
        }

        .ona-picture{
            margin-top: 110px;
            left: -65px;
            position: absolute;

            @media $large-mobile{
                margin-bottom: 30px;
                margin-top: -335px;
                max-width: 390px;
                position: initial;
            }

             @media $large-mobile{
                 max-width: 100%;
            }
        }

        .text-content{
            margin-left: auto;
            max-width: 555px;
            

            p{
               max-width: 512px; 

                @media $large-mobile{
                    margin: auto;
                }
            }

             @media $large-mobile{
                margin: auto;
            }

             @media $large-mobile{
                 h2{
                     margin-bottom: 40px;
                 }
            }
        }

        @media $large-mobile{
            margin-top: 180px;
            padding: 160px 0px 35px;
        }
    }
</style>
<script>

export default {
   
    name: "ComponentSectionsImgWithExtendText",    
    props: {
        component: Object,
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
            banners: {},
        };
    },
}
</script>


