<template>
      <section class="list_items" v-if="component">
        <div class="center-content lists">
          <div class="lists__items_title" v-if="component.title">
            <h2 v-if="component.title">{{ component.title }}</h2>
            <p v-if="component.description" v-html="component.description"></p>
          </div>
          <div  class="lists__list" 
              :style="{ gridTemplateColumns:  'repeat(' + component.collumns + ', 1fr)' }">
            <article  v-for="list in component.Itens" :key="list">
              <figure class="lists_list_item" v-if="list.icon.data">
                <img v-if="list.icon.data" :src="api_url + list.icon.data.attributes.url" ref="image_bg">
              </figure>
              <figure class="lists_list_item" v-if="list.codigo_svg" v-html="list.codigo_svg">
              </figure>
              <div class="list-text" v-html="list.text"></div>
            </article> 
          </div>         
        </div>       
      </section>
</template>
<style lang="styl">
.list_items {
  padding-bottom: 50px;
}
.lists__items_title {  
   padding: 60px 30px 0 0;
   text-align: center;
   h2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 40px;
      color: #3B3B3A;
   }
  
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #3B3B3A;
    margin-top: 30px;
  }
  @media $tablet-max  {
    padding:30px 10px;
    text-align:left;
    p {
      margin-top: 15px;
    }
  }

}
.lists_list_item {
  width:40px;
  height:40px;  
  flex-shrink: 0;
  display:flex;
  img {
    height:100%;
    width:auto;
  }
  @media $mobile-max {
    align-self: center;
  }
}
.lists__list{
  display: grid;  
  gap: 20px;
  margin-top: 0;
  width: 100%;
  padding-bottom:30px;
  margin: 0 !important;
  
  article {
    max-width: 100%;
    background: #FFF;
    margin: 10px 0;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    padding: 24px;
    gap: 18px;
    align-items: center;
   
    @media $mobile-max {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      gap: 18px;
    }
  }
  @media $large-mobile {
    grid-template-columns: 1fr !important;
  }
  .list-text p {        
      padding-bottom:10px;
      line-height: 150%;     
      &:last-child {
        padding-bottom:0;
      }
  }
}
</style>
<script>
export default {
  name: "ComponentSectionsCard",
  props: {
    component: Object,   
  },  
  data() {
    return {
      api_url: process.env.VUE_APP_STRAPI_URL,
    };
  }    
};
</script>
