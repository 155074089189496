<template>
    <div class="component-cards-accordion" v-if="component">
        <img  class="cards-with-accordion_page-background"
        :src="api_url  + component.background_media.data.attributes.url" alt="">
        <div class="center-content" >          
            <h2 class="cards-accordion__title">{{ component.title }}</h2>
            <form
            action="">
                <input type="text" placeholder="Pesquisar" name="search" id="search" > 
            </form>            
            <div class="cards-accoridon__list"            
              ref="slider__wrapper">
              <h3 class="cards-accordion__title-cards">{{ component.title_cards }}</h3>
              <div class="card-list-accordion__slider"
                  ref="slider">
                  <div  v-for="card in component.Cards" :key="card.id" >
                    <Card :component="card" />
                  </div>
              </div>
          </div>              
            <div class="cards-accordion__grid">
                <div class="cards-accordion__ballon" 
                v-for="component in component.Baloons" :key="component.id" >
                    <Ballon :component="component" />
                    <img v-if="component.image.data" :src="api_url + component.image.data.attributes.url" 
                    :alt="component.image.data.attributes.alternativeText" class="cards-accordion__ballon-image">   
                </div>
                <div class="cards-accordion__accordion">
                    <h3>{{ component.title_accordion }}</h3>
                    <Accordion v-for="component in component.Accordions" :key="component.id" :component="component" />
                </div>
            </div>
        </div>    
    </div>    
</template>
<style lang="styl">    
.component-cards-accordion {
    padding-bottom: 80px;
    @media $mobile-max {
        background: #F6F6F6;
    }
    .cards-with-accordion_page-background {
        z-index: -1;
        @media $large-mobile {
            top: 0;
            height: 524px;
        }
        @media $mobile-max {
            height: 524px;
        }
    }    
    .center-content {
        text-align: center;
        padding-top: 80px;
        position: absolute;
        top: 150px;
        left: 0;
        right: 0;
        .cards-accordion__title {
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            margin: 0px 0 52px;
            color: #D5086E;
        }
        input {
            border: 2px solid #ABABAB;
            border-radius: 100px;
            width: 100%;
            max-width: 750px;
            height: 62px;
            background: url(../../images/lupa.svg) no-repeat center right;
            background-color: #FFF;
        }
        .card {
            justify-content: center;
        }
        .cards-accordion__slider {
            .card {
                margin-top: 50px;
                width: 146px;
                height: 161px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                @media $mobile-max {
                    width: 146px;
                    height: 161px;
                } 
            }
        }
        .cards-accoridon__list {
            overflow: hidden;
            max-width: 100%;
            margin-bottom: 77px;
            .cards-accordion__title-cards {
                margin-top: 50px;
                font-size: 16px;
                h3 {
                    font-weight: 400;
                    line-height: 32px;
                    color: #3B3B3A;
                }
            }
        }
        .card-list-accordion__slider {
            display:flex;        
            gap: 20px;
            width: 990px;
            transform: translate3d(40px, 0px, 0px);
            @media $mobile-max {
                margin: 80px 0;
            } 
            .card {
                background: #FFF;
                margin-top: 50px;
                width: 146px;
                height: 161px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                transform: translate3d(-30px, 0, 0);
                @media $mobile-max {
                    width: 146px;
                    height: 161px;
                } 
            }         
        }         
        .cards-accordion__grid {
            display: grid;
            grid-template-columns: 1fr 2fr;
            margin-top: 80px;
            gap: 40px;
            @media $large-mobile {
                grid-template-columns: 1fr;
            }
            .balloon {
                text-align: left;
                padding: 10px;
            }
            .balloon-question  {
                margin: 0 0 40px 0;
                .title-balloon{
                   font-size: 21px;
                }
            }
            .balloon-answer {
                font-size: 14px;
                margin-bottom: 20px;
            }
            .cards-accordion__accordion {
                text-align: left;
                padding: 10px;
                h3 {
                    margin-bottom: 30px;
                }
            }
            
        }
    }
}
</style>
<script>
import Ballon from '../Balloon.vue'
import Card from "../Card.vue";
import Accordion from '../Accordion.vue'
export default {
    name: "ComponentSectionsParagraph",    
    props: {
        component: Object
    },
    components: {
        Ballon,
        Card,
        Accordion,
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
            dist: {
              finalPosition: 0,
              startX: 0,
              movement: 0
            },            
        };
    },    
    mounted() {
        const wrapper = this.$refs.slider__wrapper;
        wrapper.addEventListener('mousedown', this.onStart);
        wrapper.addEventListener('mouseup', this.onEnd);
        this.slidesConfig();
        this.changeSlide(0);
        this.transition(true);
    },
    methods: {
        transition(active) {
            const slider = this.$refs.slider
            slider.style.transition = active ? 'transform .3s' : '';
        },         
        moveSlide(distX) {
            this.dist.movePosition = distX;
            document.querySelector('.card-list-accordion__slider').style.transform = `translate3d(${distX}px, 0, 0)`;
        }, 
        updatePosition(clientX) {
            this.dist.movement = (this.dist.startX - clientX) * 1.6;
            return this.dist.finalPosition - this.dist.movement;
        },    
        onStart(event){ 
            event.preventDefault();
            this.dist.startX = event.clientX;
            document.querySelector('.card-list-accordion__slider').addEventListener('mousemove', this.onMove);
            this.transition(false);
        },
        onMove(event) {
            const finalPosition = this.updatePosition(event.clientX);
            this.moveSlide(finalPosition);
        },
        onEnd(event) {            
            document.querySelector('.card-list-accordion__slider').removeEventListener('mousemove', this.onMove);
            this.dist.finalPosition = this.dist.movePosition;
            this.transition(true);
            this.changeSlideOnEnd();            
        },
        changeSlideOnEnd() {
            if (this.dist.movement > 120 && this.index.next !== undefined) {
                this.activeNextSlide();
            } else if (this.dist.movement < -120 && this.index.prev !== undefined) {
                this.activePrevSlide()
            } else {
                this.changeSlide(this.index.active)
            }
        },        
        slidePosition(slide) {
            const wrapper = this.$refs.slider__wrapper;
            const margin = (wrapper.offsetWidth - slide.offsetWidth) / 2;
            return - (slide.offsetLeft - margin);
        },
        slidesConfig() {
            const slider = this.$refs.slider;
            this.slideArray = [...slider.children].map((element) => {
                const position = this.slidePosition(element);
                return { position, element };
            });
        },
        slideIndexNav(index) {
        const last = this.slideArray.length - 1;
            this.index = {
                prev: index ? index - 1 : undefined,
                active: index,
                next: index === last ? undefined : index + 1,
            };
        },
        changeSlide(index) {
            const activeSlide = this.slideArray[index];
            this.moveSlide(activeSlide.position);
            this.slideIndexNav(index);
            this.dist.finalPosition = activeSlide.position;
            this.changeActiveClass();
        },
        changeActiveClass() {
            this.slideArray.forEach(item => item.element.classList.remove('active'));      
            this.slideArray[this.index.active].element.classList.add('active');
        },
        activePrevSlide() {
            if (this.index.prev !== undefined) this.changeSlide(this.index.prev);   
        },
        activeNextSlide() {
            if (this.index.next !== undefined) this.changeSlide(this.index.next);
        },       
    }
}
</script>


