<template>
  <div class="bg-white menu-main">
    <div class="menu-container">
      <h1 class="logo-site">
        <a href="#home" class="links" accesskey="1">
          <img
            v-if="logo.data"
            :alt="logo.data.attributes.alternativeText"
            :src="site_url + logo.data.attributes.url"
          />
        </a>
      </h1>
      <nav id="main-nav" class="main-nav" :class="{ openmenu: mobileMenu }">
        <ul class="main-nav-list">
          <router-link
            v-for="nav in navList"
            :to="{ path: '/' + nav.link }"
            :key="nav.link"
            custom
            v-slot="{ href }"
          >
            <li class="main-menu" :class="{ 'has-sub-menu': nav.childs.length > 0 }">
              <a
                :href="nav.type == 'EXTERNAL' ? nav.link : href"
                @click="navigate"
                class="link-menu"
                >{{ nav.title }}</a
              >
              <ul class="sub-menu" v-if="nav.childs.length > 0">
                <li v-for="child in nav.childs" :key="child.id">
                  <router-link
                    :to="{ path: '/' + child.link }"
                    :key="child.link"
                    custom
                    v-slot="{ href }"
                  >
                    <a
                      :href="child.type == 'EXTERNAL' ? child.link : href"
                      @click="navigate"
                      class="link-menu link-submenu"
                      >{{ child.title }}</a
                    >
                  </router-link>
                </li>
              </ul>
            </li>
          </router-link>
          <li class="search-wrap">
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="search-icon"
              @click="toggleSearch()"
            >
              <path
                d="M15.535 14.724C15.2421 14.4311 14.7672 14.4311 14.4743 14.7239C14.1814 15.0168 14.1814 15.4917 14.4742 15.7846L15.535 14.724ZM20.7222 22.0335C21.0151 22.3264 21.49 22.3264 21.7829 22.0336C22.0758 21.7407 22.0759 21.2658 21.783 20.9729L20.7222 22.0335ZM12.7524 17.6625C17.2284 15.7603 19.3148 10.5897 17.4125 6.11376L16.032 6.70046C17.6103 10.414 15.8792 14.7038 12.1657 16.282L12.7524 17.6625ZM17.4125 6.11376C15.5103 1.63779 10.3397 -0.448628 5.86376 1.45361L6.45046 2.83412C10.164 1.2559 14.4538 2.98692 16.032 6.70046L17.4125 6.11376ZM5.86376 1.45361C1.38779 3.35586 -0.69863 8.52642 1.20361 13.0024L2.58411 12.4157C1.0059 8.70215 2.73692 4.41233 6.45046 2.83412L5.86376 1.45361ZM1.20361 13.0024C3.10585 17.4784 8.27642 19.5648 12.7524 17.6625L12.1657 16.282C8.45215 17.8603 4.16233 16.1292 2.58411 12.4157L1.20361 13.0024ZM14.4742 15.7846L20.7222 22.0335L21.783 20.9729L15.535 14.724L14.4742 15.7846Z"
                fill="#3B3B3A"
              />
            </svg>

            <div class="search-form" :class="{ show: searchForm }">
              <form action="/busca">
                <label for="buscaInput" hidden>Buscar</label>
                <input type="text" name="q" id="buscaInput" placeholder="Buscar..." />
                <button aria-label="Icone Procurar" type="submit">
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="search-icon"
                  >
                    <path
                      d="M15.535 14.724C15.2421 14.4311 14.7672 14.4311 14.4743 14.7239C14.1814 15.0168 14.1814 15.4917 14.4742 15.7846L15.535 14.724ZM20.7222 22.0335C21.0151 22.3264 21.49 22.3264 21.7829 22.0336C22.0758 21.7407 22.0759 21.2658 21.783 20.9729L20.7222 22.0335ZM12.7524 17.6625C17.2284 15.7603 19.3148 10.5897 17.4125 6.11376L16.032 6.70046C17.6103 10.414 15.8792 14.7038 12.1657 16.282L12.7524 17.6625ZM17.4125 6.11376C15.5103 1.63779 10.3397 -0.448628 5.86376 1.45361L6.45046 2.83412C10.164 1.2559 14.4538 2.98692 16.032 6.70046L17.4125 6.11376ZM5.86376 1.45361C1.38779 3.35586 -0.69863 8.52642 1.20361 13.0024L2.58411 12.4157C1.0059 8.70215 2.73692 4.41233 6.45046 2.83412L5.86376 1.45361ZM1.20361 13.0024C3.10585 17.4784 8.27642 19.5648 12.7524 17.6625L12.1657 16.282C8.45215 17.8603 4.16233 16.1292 2.58411 12.4157L1.20361 13.0024ZM14.4742 15.7846L20.7222 22.0335L21.783 20.9729L15.535 14.724L14.4742 15.7846Z"
                      fill="#3B3B3A"
                    />
                  </svg>
                </button>
              </form>
              <button @click="toggleSearch()" class="close-form" aria-label="Fechar Menu">X</button>
            </div>
          </li>
        </ul>
      </nav>
      <Button
        v-if="active_level_short == 'beneficiario'"
        href="planos-amil-one"
        text="Planos Amil One"
        class="btn-beneficiario"
      ></Button>
      <Button v-else href="contrate-agora" text="Contrate" aria-label="Contrate Agora"></Button>
      <button @click="libras" class="central-libras-btn">
        <img src="@/images/central-libras-desk.svg" alt="Central de Libras">
        <span>Central de Libras</span>
      </button>
      <button
        class="btn-mobile-hamburger"
        id="btn-menu"
        aria-label="Menu Mobile"
        :class="{ openmenu: mobileMenu }"
        @click="showMobileMenu"
      >
        <svg
          width="22"
          height="14"
          viewBox="0 0 22 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 12.25C0.585786 12.25 0.25 12.5858 0.25 13C0.25 13.4142 0.585786 13.75 1 13.75V12.25ZM20.5 13.75C20.9142 13.75 21.25 13.4142 21.25 13C21.25 12.5858 20.9142 12.25 20.5 12.25V13.75ZM1 13.75H20.5V12.25H1V13.75Z"
            fill="#3B3B3A"
          />
          <path
            d="M1 6.25C0.585786 6.25 0.25 6.58579 0.25 7C0.25 7.41421 0.585786 7.75 1 7.75V6.25ZM20.5 7.75C20.9142 7.75 21.25 7.41421 21.25 7C21.25 6.58579 20.9142 6.25 20.5 6.25V7.75ZM1 7.75H20.5V6.25H1V7.75Z"
            fill="#3B3B3A"
          />
          <path
            d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM20.5 1.75C20.9142 1.75 21.25 1.41421 21.25 1C21.25 0.585786 20.9142 0.25 20.5 0.25V1.75ZM1 1.75H20.5V0.25H1V1.75Z"
            fill="#3B3B3A"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<style lang="styl">
.central-libras-btn {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  color: #3B3B3A;
  span {
    @media $mobile-max {
      display: none;
    }
  }
}
.btn-beneficiario {
  font-size: 15px;
}
.btn-mobile-hamburger {
  display:none;
  border:0;
  background:none;
  svg {

    path {
      transition: all 0.3s ease;
      transform-box:fill-box;
    }
  }
  &.openmenu {
    svg {
      path:nth-child(1) {
        transform: rotate(-35deg);
      }
      path:nth-child(2) {
        opacity: 0;
      }
      path:nth-child(3) {
        transform: rotate(35deg);
      }
    }
  }
  @media (max-width: 1000px) {
    display: block;
  }
}


.logo-site {
  flex-shrink: 0;
  img {
    width: 76px;
    @media $tablet-max {
      width:65px;
    }
  }
}
.menu-container {
  width:100%;
  @media $tablet-max {
    padding:0 15px;
  }
  .main-nav {
    margin-left:auto;
    @media (max-width: 1000px) {
      display:none;
      position: fixed;
      left:0;
      top:70px;
      padding:20px;
      width:100vw;
      height:100vh;
      background:#fff;
      z-index:1000;
      &.openmenu {
        display:block;
      }
      .main-nav-list {
        flex-direction: column;
        text-align:left;
        padding: 0 20px;
        align-items:flex-start;
        justify-items:flex-start;
        .main-menu {
          font-size:16px;
          font-weight:600;
          ul {
            li {
              text-align: center;
            }
          }
        }

      }
    }
  }
  .btn-more {
    @media (max-width: 768px) {
      max-width: 162px;
      height: 32px;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-left:auto;
    }
  }
}
.menu-container {
  gap: 20px
  &, .main-nav-list {
    display:flex;
    align-items:center;
    justify-items:center;
    justify-content:space-between;
  }
  .main-nav-list {
    gap:18px;
    .main-menu {
      margin-bottom:0;
    }
    @media $tablet-max {
      gap:15px;
    }

    @media $mobile-max {
      gap:10px;
    }
  }
}


.link-menu {
  font-size:0.9375rem;
  &:hover {
    color: #D5086E;
  }
  
}

.menu-main {
  padding-bottom: 9px;
   @media $mobile-max {
    font-size:14px;
   }
}
.search-icon {
  cursor:pointer;
}
.search-wrap {
  position:relative;
  margin-bottom:0;
  flex-shrink: 0;
  .search-form {
    display:none;
    position:absolute;
    top:0;
    right:0;
    width:320px;
    height:100%;
    transform: translateY(-20%);
    align-items:center;
    background:#fff;
    padding:20px;
    z-index:100;
    border:1px solid #cecece;
    border-radius:10px;
    text-align: left;
    justify-content: flex-start;
    form {

      button {
        padding:0;
        border:0;
        background:transparent;
        margin-left:10px;
      }
      input {
        background: #fff;
        border:1px solid #cecece !important;
        padding:5px 20px;
        border-radius: 25px;

        &::placeholder {
          color: #3B3B3A;
        }
      }
    }
    .close-form {
      position:absolute;
      top:0;
      right:0;
      width: 40px;
      height: 40px;
      cursor:pointer;
      border:0;
      background:none;
    }
    &.show {
      display:flex;
      transition: all .5s;
    }
    
  }
  @media (max-width: 1000px) {
     width:100%;
    .search-form {
      width:100%;
      display:block;
      position:static;
      margin-top:20px;
      transition: all .5s;
    }
    form {
      display:flex;
      input {
        flex-grow:1;
      }
    }
    .close-form {
      display:none;
    }
    &>svg {
      display:none;
    }
  }
}
</style>

<script>
import axios from "axios";
import Button from "./Button.vue";
export default {
  name: "VueNav",
  props: {
    idMenu: String,
    logo: Object,
  },
  components: {
    Button,
  },
  data() {
    const active_level = localStorage.getItem("active_level")
      ? localStorage.getItem("active_level")
      : (this.active_level = "Visitante");
    const active_level_short = localStorage.getItem("active_level_short")
      ? localStorage.getItem("active_level_short")
      : (this.active_level_short = "visitante");
    return {
      site_url: process.env.VUE_APP_STRAPI_URL,
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      categories: [],
      navList: [],
      mobileMenu: false,
      searchForm: false,
      active_level,
      active_level_short,
    };
  },
  mounted() {    
    const link = document.querySelector('.links');
    const path = window.location.pathname;
    if (path.match(/\/\w+/) !== null) link.setAttribute('href', '/');
    else {
      link.setAttribute('href', '#home');
      link.addEventListener('click', this.scrollToSection);
    }
  },
  methods: {
    showMobileMenu: function () {
      this.mobileMenu = !this.mobileMenu;
    },
    toggleSearch: function () {
      this.searchForm = !this.searchForm;
    },
    scrollToSection: function (event) {
      event.preventDefault();
      window.scrollTo({ behavior: 'smooth', top: 0 });
    },
    libras: function () {
      window.location = '/central-de-libras'
    }
  },
  async created() {
    const menu = this.$props.idMenu ? this.$props.idMenu : "main-navigation";
    const res = await axios.get(
      process.env.VUE_APP_STRAPI_API_URL + "/navigation/render/" + menu + "?sort=order"
    );
    const navChilds = new Array();
    res.data.sort(function (a, b) {
      return a.order - b.order;
    });
    this.navList = res.data;
    const arrayDelete = [];
    this.navList.forEach((element) => {
      element.childs = [];
      if (element.related) {
        element.link = element.related.slug;
      }
      if (element.type == "EXTERNAL") {
        element.link = element.externalPath;
      }
      if (!element.link) {
        element.link = element.path;
      }

      if (element.parent) {
        if (!navChilds[element.parent.id]) {
          navChilds[element.parent.id] = [element];
        } else {
          navChilds[element.parent.id].push(element);
        }
        arrayDelete.push(element.id);
      }
    });
    //remove first element from arrayDelete
    const arrayFiltered = this.navList.filter((element) => {
      return arrayDelete.indexOf(element.id) === -1;
    });

    arrayFiltered.forEach((element) => {
      if (!element.childs) {
        element.childs = [];
      }
      if (navChilds[element.id]) {
        navChilds[element.id].forEach((child) => {
          element.childs.push(child);
        });
      }
    });
    this.navList = arrayFiltered;
  },
};
</script>
