<template>
    <div class="component-text-with-background" v-if="component" 
    :class="{ dark_background: component.dark_background, gray_background: component.gray_background }">
        <div class="center-content" ref="wrapperParagraph">
            <div class="text_background__image"         
            :class="{  to_right: component.to_right }">
                <img v-if="component.background_Image.data" 
                :src="api_url + component.background_Image.data.attributes.url" 
                :alt="component.background_Image.data.attributes.alternativeText"
                :class="component.add_background ? 'background_image' : 'removeBackground'">   
                <img v-if="!component.svg_element && component.image.data" :src="api_url + component.image.data.attributes.url" :alt="component.image.data.attributes.alternativeText" class="text-bg-img" ref="image_bg">
                <div v-if="component.svg_element" v-html="component.svg_element" class="svg_element" :class="component.animacao"></div>
            </div>     
            <div class="container">          
                <h2 v-if="component.title">{{ component.title }}</h2>
                <p v-if="component.description" v-html="component.description" class="description"></p>
                <div class="break"></div>
                <div class="list-text" v-if="component.list_text" v-html="component.list_text"></div>
                <h3 v-if="component.title_alternative">{{ component.title_alternative }}</h3>
                <p v-if="component.description_alternative" v-html="component.description_alternative" class="description"></p>
                <p v-if="component.text_featured" v-html="component.text_featured" class="text-featured"></p>
                <a  class="text-with-image-link"
                    v-if="component.button_text" 
                    :href="component.button_link">
                    {{ component.button_text }}
                </a>      
            </div>
        </div>    
    </div>    
</template>
<style lang="styl">   

.rotate svg.animated {    
    & > path:nth-child(1) {
        animation: rotating 2s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }        
    & > path:nth-child(2) {
        animation: rotating 1s reverse;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(2) {
        animation: rotating 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(3) {
        animation: rotating 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    } 
    & > g > path:nth-child(1) {
        animation: rotating 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(2) {
        animation: rotating 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }      
}

.scale svg.animated {    
    & > path:nth-child(1) {
        animation: scale 2s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }        
    & > path:nth-child(2) {
        animation: scale 2s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(2) {
        animation: scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(3) {
        animation: scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(1) {
        animation: scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(2) {
        animation: scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }         
}

.fade svg.animated {    
    & > path:nth-child(1) {
        animation: fade 4s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }        
    & > path:nth-child(2) {
        animation: fade 4s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(2) {
        animation: fade 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(3) {
        animation: fade 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(1) {
        animation: fade 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(2) {
        animation: fade 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
}


.fade_scale svg.animated {    
    
    & > path:nth-child(1) {
        animation: fade_scale 2s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }        
    & > path:nth-child(2) {
        animation: fade_scale 2s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
     & > title ~ path:nth-child(2) {
        animation: fade_scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(3) {
        animation: fade_scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(1) {
        animation: fade_scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(2) {
        animation: fade_scale 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    } 
}

.fade_rotate svg.animated {
    & > path:nth-child(1) {
        animation: fade_rotate 3s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }        
    & > path:nth-child(2) {
        animation: fade_rotate 2s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
     & > title ~ path:nth-child(2) {
        animation: fade_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(3) {
        animation: fade_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }   
    & > g > path:nth-child(1) {
        animation: fade_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(2) {
        animation: fade_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
}

.scale_rotate svg.animated {
    & > path:nth-child(1) {
        animation: scale_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > path:nth-child(2) {
        animation: scale_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }        
    & > title ~ path:nth-child(2) {
        animation: scale_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > title ~ path:nth-child(3) {
        animation: scale_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(1) {
        animation: scale_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }
    & > g > path:nth-child(2) {
        animation: scale_rotate 1s ;
        transform-box: fill-box;
        transform-origin: 50%;
    }

}

@keyframes scale_rotate {
    0% {
        transform: scale(0) rotate3d(0,0,1,0deg);
    }    
    100% {
        transform: scale(1) rotate3d(1,1,1,360deg);
    }
}

@keyframes fade_rotate {
    0% {
        opacity: 0;
        transform: rotate3d(0, 0, 1, 0deg);
    }
  
    100% {
        opacity: 1;
        transform: rotate3d(1, 1, 1, 360deg);
    }
}

@keyframes fade_scale {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scale {
    0% {
        transform: scale(0.2);
    }        
    100% {
        transform: scale(1);
    }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate3d(0, 0, 1, 0deg);

  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate3d(1, 1, 1, 360deg);
  }
}
.component-text-with-background {
    background: #FFF;
    overflow:hidden;
    position: relative;    
    .svg_element {
        z-index:1;
        @media $large-mobile {
            max-width: 80%;
        }

    }
    .center-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: left;
        gap: 80px;
        padding: 70px 0;
        height: 100% !important;
        @media $large-mobile {
            height: auto !important;
        }       
        @media $large-mobile {
           grid-template-columns: 1fr;
        } 
        img {
            @media $large-mobile {
                margin: 0 auto;
            }
        }
        .text_background__image {            
            display: flex;
            justify-content: center;
            align-self: center;
            flex-shrink:0;
            .text-bg-img {
                max-width: 380px;
                z-index:1;
                @media $large-mobile {
                    padding: 30px;
                }
            }
        }
        .removeBackground {
            display: none;
        }
       
        .background_image {
            display: block ;
            position: absolute;
            z-index: 0;
            width:50%;
            top:50%;
            transform:translateY(-50%);
            @media $large-mobile {
                display: none;
            }
        }
        .container {
            display: flex;            
            align-items:center;
            align-content: center;
            padding: 50px 0;
            flex-wrap:wrap;
            @media $large-mobile {
                align-items: center;
                text-align: center;
                order: 3;   
                padding: 0;             
            }  
            h2, p {
                color: #3B3B3A;
            }
            h2, h3 {
                color: #D5086E;
                font-weight: 500;
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 30px;
                width: 100%;
                @media $large-mobile {
                    font-size: 22px;
                    line-height: 32px;                    
                }
            }
            .description {
                width: 100%;
                color: #3B3B3A;
                margin-bottom: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 38px; 
                @media $large-mobile {
                    font-size: 14px;
                    line-height: 30px;                    
                }
            }
            .break {
                width:100%;
                height:0.1px;
            }
            .text-featured {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 12px 16px;
                width: 100%;
                height: 52px;
                background: #FFFFFF;
                border-radius: 8px;
                font-size: 16px;
                margin-top: 20px;
            }           
            .list-text {
                ul {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    li {
                        padding: 12px 16px;
                        height: 80px;
                        background: #FFF;
                        border-radius: 8px;
                        display: flex;
                        margin-bottom: 10px;
                        gap: 5px;
                    }
                }
            }
            .text-with-image-link {
                font-family: 'Amil Typeface';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 19px;
                position: relative;                 
                color:#D5086E;    
                text-align:center;
                display:inline-block;
                @media $large-mobile {
                    margin-top: 20px;
                    margin: 15px auto 0 auto;
                    font-size: 14px;
                    line-height:14.75px;
                  
                }
                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: #D5086E;
                    margin: 10px auto;
                    position: absolute;
                    left:0;
                    @media $large-mobile {
                        margin: 0 auto;
                    }
                    
                }
            }
        }
    }
}

</style>
<script>
import Button from '../Button.vue'
export default {
   
    name: "ComponentSectionsParagraph",    
    props: {
        component: Object
    },
    data() {        
        return {            
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },    
    components: {
        Button,
    },  
    mounted() {
        const svg = document.querySelectorAll('.svg_element svg');        

        //add class animate to svg when enter on viewport
        const observer = new IntersectionObserver((entries) => {
            
            entries.forEach(entry => {
                
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('animated');
                } else {
                    entry.target.classList.remove('animated');
                }
            });
        });
        svg.forEach(svg => {
            observer.observe(svg);
        });
        this.calculateImageSize();
        //on resize calculate image size again
        window.addEventListener('resize', this.calculateImageSize);
    },
    methods: {
        calculateImageSize() {
            
            //get image bg size
            const image_bg = this.$refs.image_bg;        
            if(image_bg) {
                const image = new Image();
                image.src = image_bg.src;            
                image.onload = () => {
                    const image_bg_width = image.naturalWidth;
                    const image_bg_height = image.naturalHeight;        
                    const wrapper = this.$refs.wrapperParagraph;
                    const proportion = image_bg_height / image_bg_width;                    
                    const document_width = document.documentElement.clientWidth;                
                    const new_image_width = document_width * 0.65;
                    const new_image_height = new_image_width * proportion;                    
                    wrapper.style.height = new_image_height + 'px';                
                }
            }
            
        }
    }
}
</script>


