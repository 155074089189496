<template>
    <div class="">
        <div v-if="component">            
        </div>
    </div>    
</template>
<script>
export default {
    name: "ComponentSectionsFullBanner",
    props: {
        component: Object,
    },
    data() {        
        return {
            banners: {},
        };
    },
}
</script>
