<template>
    <img 
        v-if="arrow == true"
        accesskey="1"
        @click="scrollTop" 
        class="arrow_fixed" 
        src="@/images/seta.svg" 
        alt="Subir para o topo">
</template>

<style lang="styl">
.arrow_fixed {
    width: 58px;
    height: 50px;
    position: fixed;
    transform: rotate(-180deg);
    right: 20px;
    z-index: 999999999999;
    bottom: 20px;
    background: #D5086E;
    border-radius: 4px;
    padding: 10px 12px;
    cursor: pointer;
    transition: .3s;
}
</style>
<script>
export default {
    name: "ComponentArrow",
    props: {
        component: Object,
    },
    data() {
        return {    
            arrow: false
        };
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 0) this.arrow = true;
            else this.arrow = false;
        },
        scrollTop() {
            window.scrollTo({
                behavior: 'smooth', 
                top: 0 
            });
        }
    }
}
</script>