<template>
    <div v-if="component" class="text-widget">   
        <h3 v-if="component.title"> {{component.title}}</h3>
        <div class="text-widget__text"></div>
        <div class="text-widget__icons" v-html="images(component.text)"> </div>     
    </div>
</template>
<script>
export default {
    name: "ComponentPartsImage",
    props: {
        component: Object,
        wrapperClasses: {
            type: String,
            default: "",
        },
    },
    methods: {
        images: function (value) {
        if (!value) return ''
        value = value.toString();
        //add api_url on image src start
        value = value.replace(/src="\//g, 'src="' + this.api_url + '/');
        return value                
        },
        link() {
            const links = document.querySelectorAll('.text-widget__icons p a');
            
            links.forEach(link => { link.setAttribute('target', '_blank')});
        }
    },
    mounted() {
        this.link();
    },
    data() {        
        return {
            api_url: process.env.VUE_APP_STRAPI_URL,
        };
    },
}
</script>
<style lang="styl">
    .text-widget {
        color:#fff;
        @media $large-mobile {
            text-align: left !important;
            padding: 20px;
        }
        .text-widget__link {
            color:#fff;
            line-height:1em;
            padding-bottom:0;
            margin-bottom: 20px;
            font-size: 22px;
            display: block;
            font-weight: 500;
            &:after {
                content:'';
                display:block;
                background:#D5086E;
                width: 18px;
                border-radius: 30px;
                height: 5px;                
            }            
        }
        h3 {
            color:#fff;
            line-height:1em;
            padding-bottom:0;
            margin-bottom: 20px;
            &:after {
                content:'';
                display:block;
                background:#D5086E;
                width: 18px;
                border-radius: 30px;
                height: 5px;                
            }
        }
        .text-widget__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            p {
                margin-bottom: 24px !important;
            }
            a {
                span {
                    font-weight: 700;
                    font-size: 28px !important;
                    line-height: 25px;
                    margin-top: 19px;
                    @media $mobile-max {
                        display: block;
                    }
                }
            }
        }
        .text-widget__icons {
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                span {
                    font-size: 24px !important;
                    @media $mobile-max {
                        display: block;
                    }                    
                }
            }
        }
        h3 {
            font-size:22px;
            font-weight: 500;
        } 
        h3 {
            margin-top: 8px;
        } 
        figure {
            display: inline !important;
            margin-right: 15px;
            img {
                margin-bottom: 20px;
            }
        }
    }
    
     ul {
        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            margin-bottom: 30px;
        }
    }
</style>